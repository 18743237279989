import React from 'react';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { Row, SizedBox } from '@common/components/layout.style';
import Utils from '@utils/utils';
import { PhoneNumber } from '@pages/UpdateNewPosCustomer/components/Phone/PhoneNumber';
import { PhonePrefix } from '@pages/UpdateNewPosCustomer/components/Phone/PhonePrefix';
import { PhoneType } from '@pages/UpdateNewPosCustomer/components/Phone/PhoneType';
import styled, { css } from 'styled-components/macro';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ConnectForm } from '@/common/components/form/index';
import { InformationFormProps, PhoneField } from '@/types/form/informationFormProps';
import {
  TextFieldComponentEnum, ValuesListFieldComponentEnum
} from '@/types/enum/FieldComponent.enum';
import { ValuesListFieldComponent } from '@/types/api';

export const Phones: React.FC = () => (
  <ConnectForm>
    {({ control, fieldsConfiguration }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { fields } = useFieldArray<PhoneField>({ name: 'phones', control: control! });
      const phoneNumberFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.PHONE_NUMBER);
      const phonePrefixFieldInfos = Utils.getFieldComponent(fieldsConfiguration, ValuesListFieldComponentEnum.PHONE_PREFIX);
      const phoneTypeFieldInfos = Utils.getFieldComponent<ValuesListFieldComponent>(fieldsConfiguration, ValuesListFieldComponentEnum.PHONE_TYPE);

      return (
        <div>
          {fields.length > 0 && fields.map((field, index) => (
            <div key={field.id}>
              <StyledRow $alignItems="flex-start">
                {phoneTypeFieldInfos?.isAuthorized && <PhoneType name={`phones.${index}.kind`} defaultValue={field.kind} />}
                <SizedBox $width={10} />
                {phonePrefixFieldInfos?.isAuthorized && <PhonePrefix name={`phones.${index}.prefix`} defaultValue={field.prefix} />}
                <SizedBox $width={10} />
                {phoneNumberFieldInfos?.isAuthorized && <PhoneNumber name={`phones.${index}.number`} index={index} defaultValue={field.number} />}
              </StyledRow>
              <SizedBox $height={15} />
            </div>
          ))}
        </div>
      );
    }}
  </ConnectForm>
);

const StyledRow = styled(Row)`
    width: 100%;
    ${(props) => props.theme.language === 'ar' && css`
        direction: rtl;
    `}
    & > :nth-child(1) {
        flex: 1;
    }
    & > :nth-child(3) {
        flex: 2;
    }
    & > :nth-child(5) {
        flex: 4;
    }
`;
