import React, { Dispatch, SetStateAction } from 'react';
import { useStateMachine } from 'little-state-machine';
import { LegalReasons } from '@pages/CountryPage/countryPage.styles';
import CountryService from '@pages/CountryPage/country.service';
import { useNavigate } from 'react-router-dom';
import { CountryStateButton } from '@/common/components';
import { useTranslation } from '@/services/hooks';
import { Country } from '@/types';
import { PageID } from '@/types/enum/pageID';
import {
  updateBrandCountryConfiguration,
  updateCustomerCountry,
  updateErrorTypeOnError,
  updateFeaturesActivationAndPageFlow,
  updateIsLoading
} from '@/littleStateMachine/actions';

type Props = {
  isWriting: boolean,
  defaultCountry?: Country,
  localStep: PageID,
  token: string,
  setSocialLoginModalDisplayed: Dispatch<SetStateAction<boolean>>,
}

export const LegalTextAndDefaultCountry: React.FC<Props> = ({ isWriting, defaultCountry, localStep, token, setSocialLoginModalDisplayed }) => {
  // Prepare navigation
  const navigate = useNavigate();

  const t = useTranslation();
  const { actions, state } = useStateMachine({
    updateCustomerCountry,
    updateBrandCountryConfiguration,
    updateErrorTypeOnError,
    updateFeaturesActivationAndPageFlow,
    updateIsLoading
  });
  return (
    <>
      {!isWriting
      && (
        <>
          <LegalReasons>
            {t('web.legalReasonsCountry')}
          </LegalReasons>
          { defaultCountry && (
          <CountryStateButton
            value={defaultCountry.code}
            key={defaultCountry.code}
            type="button"
            onMouseDown={() => CountryService.onCountryButtonClick(defaultCountry, actions.updateCustomerCountry, actions.updateBrandCountryConfiguration, actions.updateErrorTypeOnError, actions.updateFeaturesActivationAndPageFlow, actions.updateIsLoading, state, localStep, token, navigate, setSocialLoginModalDisplayed)}
          >
            {t(`country.${defaultCountry.code.toLocaleLowerCase()}`)}
          </CountryStateButton>
          )}
        </>
      )}
    </>
  );
};
