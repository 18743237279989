import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import styled from 'styled-components/macro';
import { StyledSelect } from '@common/components/form/Select';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { ConnectForm } from '@common/components/form';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { EmailTypeEnum } from '@/types/enum/email-type.enum';

type Props = {
  name: string,
  defaultValue: string,
}

export const EmailTypeSelect = styled(StyledSelect)`
    width: 27%;
    min-width: 27%;
    appearance: auto;
`;

export const EmailType: React.FC<Props> = ({ name, defaultValue }) => (
  <ConnectForm>
    {({ register }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <EmailTypeSelect
        name={name}
        id={name}
        ref={register({})}
        defaultValue={defaultValue}
        key={name}
      >
        {Object.values(EmailTypeEnum).map((emailType) => <option value={emailType} key={name + emailType}>{emailType}</option>)}
      </EmailTypeSelect>
    )}
  </ConnectForm>
);
