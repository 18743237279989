import { Country } from '@/types/country';
import { Translation } from '@/types/translation';
import { Brand } from '@/types/brand';
import { Language } from '@/types/language';
import { ProspectSource } from '@/types';
import { EventType } from '@/types/Event';
import { PageID } from './enum/pageID';
import { CtaComponent } from '@/types/ctaComponent';

export type LightConfiguration = {
  prospectSource: ProspectSource,
  languagesAvailable: Array<Language>,
  generationDate: string,
  brand: Brand,
  i18n: Translation,
  countries: Array<Country>,
  store: {
    storeCode: string,
    countryCode: string,
    address: string,
    name: string,
    phone: string,
    region: string
  },
  sa: {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    vendorId: string,
  },
  event: EventType,
  prefilledClientData?: PrefilledClientData,
  queueManagement?: QueueManagement,
  featuresActivated: {[key in FeatureActivated]: boolean },
  pageFlow: PageFlow,
  mixPanelToken: string,
  appleClientId: string,
  certificateOfCraftCtaComponent: CtaComponent
}

export interface PageFlow {
  flow: FlowName,
  pages: Page[],
}

export enum FlowName {
  CREATE = 'CREATE',
  CREATE_AND_EDIT = 'CREATE_AND_EDIT',
  UPDATE_ADDRESS = 'UPDATE_ADDRESS',
  CREATE_GUCCI = 'CREATE_GUCCI'
}

export interface Page {
  name: PageID,
  stepValue: number
}

export interface Flow extends PageFlow {
  authorizedPages: Array<PageID>,
}

export type PrefilledClientData = {
  phoneNumber: {
    number: string,
    prefix: string,
    prefixCountry?: string
  },
  address?: {
    country?: string,
    postalCode?: string,
  },
  lastname: string,
  firstname: string,
  email: string
}

export type QueueManagement = {
  redirectionUrl: string,
  queueClientId: string,
  useKafkaNotification?: boolean
}

export enum FeatureActivated {
  // Display street and zip code fields on contact page
  IS_CONTACT_FORM_EXTENDED = 'IS_CONTACT_FORM_EXTENDED',
  // Display Address Page
  IS_ADDRESS_PAGE_DISPLAYED = 'IS_ADDRESS_PAGE_DISPLAYED',
  // Search precisely addresses on address page
  IS_ADDRESS_SEARCH_ACTIVATED = 'IS_ADDRESS_SEARCH_ACTIVATED',
  // Wire Edit: allow creation and edition of customers
  IS_WIRE_EDIT_FLOW_ACTIVATED = 'IS_WIRE_EDIT_FLOW_ACTIVATED',
  // Activate Kakao login feature
  IS_KAKAO_FLOW_ACTIVATED = 'IS_KAKAO_FLOW_ACTIVATED',
  // TODO remove the following lines after MEP 05/07
  // Activate upload coc feature
  IS_CERTIFICATE_OF_CRAFT_FLOW_ACTIVATED = 'IS_CERTIFICATE_OF_CRAFT_FLOW_ACTIVATED',
  // Activate wholesaler coc feature
  IS_CERTIFICATE_OF_CRAFT_WHOLESALER_FLOW_ACTIVATED = 'IS_CERTIFICATE_OF_CRAFT_WHOLESALER_FLOW_ACTIVATED',
  // Activate gift coc feature
  IS_CERTIFICATE_OF_CRAFT_GIFT_FLOW_ACTIVATED = 'IS_CERTIFICATE_OF_CRAFT_GIFT_FLOW_ACTIVATED',
  // Display WeChat QR code on Registered page
  IS_WECHAT_QRCODE_DISPLAYED = 'IS_WECHAT_QRCODE_DISPLAYED',
  // Display WeChat Modal on Registered page
  IS_WECHAT_MODAL_DISPLAYED = 'IS_WECHAT_MODAL_DISPLAYED',
  // Display Line QR code on Registered page
  IS_LINE_QRCODE_DISPLAYED = 'IS_LINE_QRCODE_DISPLAYED',
  // Display Line QR codes on Registered page for Japan, Thailand and Taiwan
  ARE_JPTHTW_LINE_QRCODES_DISPLAYED = 'ARE_JPTHTW_LINE_QRCODES_DISPLAYED',
  // Display the SA VCard QR code on Registered page
  IS_SA_QRCODE_DISPLAYED = 'IS_SA_QRCODE_DISPLAYED',
  // Different key for out of store clients in case of blacklisted clients
  IS_OUT_OF_STORE_PROSPECT_SOURCE_ACTIVATED = 'IS_OUT_OF_STORE_PROSPECT_SOURCE_ACTIVATED',
  // Skipped Welcome page
  IS_WELCOME_PAGE_SKIPPED = 'IS_WELCOME_PAGE_SKIPPED',
  // Deactivate phone method of contact in specific cases
  IS_PHONE_CONTACT_METHOD_DISABLED = 'IS_PHONE_CONTACT_METHOD_DISABLED',
  // Display SL App CTA on Registered page
  IS_SL_APP_CTA_DISPLAYED = 'IS_SL_APP_CTA_DISPLAYED',
  // Wire Edit Phase 2: allow edition of existing customers without any okta account
  IS_WIRE_EDIT_PHASE_2_ACTIVATED = 'IS_WIRE_EDIT_PHASE_2_ACTIVATED',
  // todo: delete after end of Japan Wire customization ALW-2129
  IS_JP_CUSTOM_CAROUSEL_ACTIVATED = 'IS_JP_CUSTOM_CAROUSEL_ACTIVATED',
  // Display a redirect button at the end of the flow.
  IS_REDIRECT_URL_ACTIVATED = 'IS_REDIRECT_URL_ACTIVATED',
  // Check email before inscription
  IS_EMAIL_VALIDITY_CHECKED = 'IS_EMAIL_VALIDITY_CHECKED',
  // Offer to select Sale Advisor for Prospect Source DCC in IPAD
  IS_PREFERRED_SA_ACTIVATED = 'IS_PREFERRED_SA_ACTIVATED',
  // Use Gucci Create to disable Wire Edit on SMS point of contact for Gucci
  IS_GUCCI_CREATE_USED = 'IS_GUCCI_CREATE_USED',
  IS_GUCCI_SERVICES_USED = 'IS_GUCCI_SERVICES_USED',
  ARE_ADDRESS_FIELDS_DISPLAYED_ON_INFORMATION_PAGE = 'ARE_ADDRESS_FIELDS_DISPLAYED_ON_INFORMATION_PAGE',
  IS_APPLE_SOCIAL_LOGIN_ACTIVATED = 'IS_APPLE_SOCIAL_LOGIN_ACTIVATED',
  // For Wire Edit Gucci, social info in Okta are different and based on ECOM
  IS_GUCCI_DIGITAL_ACCOUNT_USED = 'IS_GUCCI_DIGITAL_ACCOUNT_USED',
  // Get field configuration from Wire API
  IS_FIELD_CONFIGURATION_GET_FROM_WIRE_API = 'IS_FIELD_CONFIGURATION_GET_FROM_WIRE_API',
  // Get legal configuration from Wire API
  IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API = 'IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API',
  // Skip legal page
  IS_REGISTERED_PAGE_SKIPPED = 'IS_REGISTERED_PAGE_SKIPPED',
  // Fill social info in customer
  IS_SOCIAL_BOOKING_TOOL_USED = 'IS_SOCIAL_BOOKING_TOOL_USED',
}
