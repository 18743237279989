import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { TYPE_DROPDOWN } from '@/types';
import { updateCustomerInformation } from '@/littleStateMachine/actions';
import { Title } from '@/common/components';
import { NextButtonContainer, FormContainer, PageContainer } from '@/common/components/containers';
import { Label } from '@/common/components/form';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import mp from '@/services/mixpanel/mixpanel.service';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import FlowService from '@/services/flow.service';
import { PageID } from '@/types/enum/pageID';
import FormHeader from '@/common/components/form/FormHeader';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';

const SocialAccounts: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.SOCIAL);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();
  const localStep = PageID.SOCIAL;

  // Retrieve current state from store
  const { actions, state } = useStateMachine({ updateCustomerInformation });
  const { brand, customer, prospectSource, flow, translations, languages, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);
  useThemeColor(brand, ThemeType.FORM);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  const onSubmit = formMethods.handleSubmit(async ({
    instagram, tiktok,
  }: InformationFormProps) => {
    const socialInfos = { instagram, tiktok };
    actions.updateCustomerInformation(socialInfos);
    mp.saveSocialAccounts(socialInfos);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  return brand.code && flow.authorizedPages.includes(localStep) ? (
    <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, prospectSource }}>
      <PageContainer>
        <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
        <Title>{t('web.socialAccounts')}</Title>
        <FormProvider {...formMethods}>
          <form>
            <FormContainer onKeyDown={(event) => Utils.validateForm(event, isValid, onSubmit)}>
              <>
                <Label htmlFor="instagram">{t('web.instagram')}</Label>
                <OptionalLegend>{t('dcc.optional')}</OptionalLegend>
                <Field
                  name="instagram"
                  id="instagram"
                  ref={formMethods.register}
                  defaultValue={customer.customerContact.socialAccounts.instagram}
                  autoComplete="off"
                  maxLength={50}
                />
              </>
              <>
                <Label htmlFor="tiktok">{t('web.tiktok')}</Label>
                <OptionalLegend>{t('dcc.optional')}</OptionalLegend>
                <Field
                  name="tiktok"
                  id="tiktok"
                  ref={formMethods.register}
                  defaultValue={customer.customerContact.socialAccounts.tiktok}
                  autoComplete="off"
                  maxLength={50}
                />
              </>
            </FormContainer>
            <NextButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.SOCIAL} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      </PageContainer>
    </ThemeProvider>
  ) : <Navigate to={`/${token}`} replace />;
};

export default SocialAccounts;
