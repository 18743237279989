import styled from 'styled-components';
import screenSizes from '@utils/screenSizes';

export const SocialLoginModalContainer = styled.div`
  overflow-y: hidden;
  position: fixed;
  height: 100vh;
  inset: 0;
`;

export const SocialLoginModalBody = styled.div`
  display: flex;
  background: white;
  border-radius: 10px 10px 0 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  row-gap: 0.5rem;
    column-gap: 8%;
`;

export const KakoBtnImg = styled.img`
  width: 100%;  
`;

export const Title = styled.div`
    display: flex;
    padding-inline: 10%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 13px;
    height: 50px;
    > span {
        text-wrap: nowrap;
    }
    @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 40%;
        padding-inline: 1rem;
    }
`;

export const CloseModalContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
