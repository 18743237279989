import React, { useEffect, useState } from 'react';
import FlowService from '@services/flow.service';
import kakaoApiService from '@services/kakao-api.service';
import mp from '@services/mixpanel/mixpanel.service';
import { useStateMachine } from 'little-state-machine';
import { useNavigate } from 'react-router-dom';
import useExternalScripts from '@services/hooks/useExternalScripts';
import KakaotalkIcon from '@assets/img/icons/KakaotalkIcon';
import SocialLoginButton from '@pages/CountryPage/components/SocialLoginButtons/SocialLoginButton';
import { useTranslation } from '@services/hooks';
import { PageID } from '@/types/enum/pageID';
import { updateIsLoading } from '@/littleStateMachine/actions';
import { LogMessageEnum } from '@/types/enum/log-message.enum';
import { LogLevelEnum } from '@/types/enum/log-level.enum';
import api from '@/api';
import { mixpanelSocialUsed } from '@/types/enum/mixpanel.enum';

interface Props {
  localStep: PageID,
}

const KakaoButton: React.FC<Props> = ({ localStep }) => {
  const { state, actions } = useStateMachine({ updateIsLoading });
  const { brand, actualToken, featuresActivated } = state;
  const [kakaoJsKey, setKakaoJsKey] = useState('');
  const navigate = useNavigate();
  const t = useTranslation();

  useExternalScripts('https://t1.kakaocdn.net/kakao_js_sdk/2.0.0/kakao.min.js', 'sha384-PFHeU/4gvSH8kpvhrigAPfZGBDPs372JceJq3jAXce11bVA6rMvGWzvP4fMQuBGL', 'anonymous');

  useEffect(() => {
    (async function fetch() {
      try {
        setKakaoJsKey(await kakaoApiService.retrieveKakaoJsKey(actualToken, brand.code));
      } catch (err) {
        await api.logInformation(actualToken, LogLevelEnum.ERROR, LogMessageEnum.KAKAO_AUTH_ERROR);
      }
    }());
  });

  const onKakaoBtnClick = async () => {
    try {
      mp.clickSocialLogin(mixpanelSocialUsed.KAKAO);
      actions.updateIsLoading(true);
      FlowService.nextPage(state, localStep);
      window.sessionStorage.setItem('__LSM__', JSON.stringify(state));
      kakaoApiService.refreshKakaoToken(kakaoJsKey);
      kakaoApiService.loginKakaoV2(`${window.location.origin}/kakao/${featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED ? 'contact' : 'identity'}`);
      actions.updateIsLoading(false);
    } catch (err) {
      await api.logInformation(state.actualToken, LogLevelEnum.ERROR, LogMessageEnum.KAKAO_INIT_ERROR);
      actions.updateIsLoading(false);
      navigate('/error');
    }
  };

  return kakaoJsKey ? (
    <SocialLoginButton id="kakaoBtnImage" label={t('web.continueWithKakao')} logo={<KakaotalkIcon />} onClick={onKakaoBtnClick} />
  ) : <p>Loading</p>;
};

export default KakaoButton;
