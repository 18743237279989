import { StyledField } from '@pages/Address/address.styles';
import React from 'react';
import { useController, UseFormMethods, useWatch } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { OptionalLegend } from '@common/components/form/Field';
import { ConnectForm, ErrorMessage, Label } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponentEnum } from '@/types/enum/FieldComponent.enum';

type ZipCodeProps = {
  name: string,
  index: number,
  defaultValue?: string
}

export const Zipcode: React.FC<ZipCodeProps> = ({ name, index, defaultValue }) => (
  <ConnectForm>
    {({ errors, t, control, fieldsConfiguration, countries }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const zipcodeFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.ZIPCODE);

      const countryField = useWatch({ name: `addresses.${index}.country`, control });
      const customerCountry = countries.find((country) => country.nameCDB === countryField);

      const { field, meta } = useController({ name,
        rules: {
          required: zipcodeFieldInfos?.isMandatory,
          pattern: Utils.stringToRegex(zipcodeFieldInfos?.regex),
          validate: (value) => Utils.doesNotContainOnlySpaces(value)
        },
        control,
        defaultValue: defaultValue || '' });

      const zipcodeError = errors.addresses?.[index]?.zipCode;

      return customerCountry?.checkZipCode && zipcodeFieldInfos?.isAuthorized ? (
        <>
          <Label htmlFor={name}>{t('dcc.zipCode')}</Label>
          {!zipcodeFieldInfos?.isMandatory && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
          <StyledField
            id={name}
            {...field}
            autoComplete="postal-code"
            disabled={!zipcodeFieldInfos?.isEditable}
            hasError={Boolean(errors.addresses?.[index]?.zipCode && meta.isTouched)}
          />
          {zipcodeError && (<ErrorMessage>{t('web.invalidFormat')}</ErrorMessage>)}
        </>
      ) : <></>;
    }}
  </ConnectForm>
);
