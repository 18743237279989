import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';
import { TextFieldComponentEnum } from '@/types/enum/FieldComponent.enum';

export const SaNote: React.FC = () => {
  const { state } = useStateMachine();
  const { fieldsConfiguration } = state;
  const saNoteCreatedOn = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.NOTE_CREATED_DATE);
  const saNoteCreatedBy = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.NOTE_SA_ID);
  const saNoteContent = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.NOTE);

  return (
    <ConnectForm>
      {({
        register,
        t
      }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
        <>
          {saNoteCreatedOn?.isAuthorized && (
            <NameComponentWrapper>
              <Label htmlFor="saNoteCreatedOn">{t('dcc.saNoteCreatedOn')}</Label>
              <Field
                id="saNoteCreatedOn"
                name="saNote.createdOn"
                ref={register({
                  required: saNoteCreatedOn.isMandatory,
                  pattern: Utils.stringToRegex(saNoteCreatedOn?.regex),
                  validate: (value) => Utils.doesNotContainOnlySpaces(value)
                })}
                disabled={!saNoteCreatedOn.isEditable}
              />
            </NameComponentWrapper>
          )}
          {saNoteCreatedBy?.isAuthorized && (
            <NameComponentWrapper>
              <Label htmlFor="saNoteCreatedBy">{t('dcc.saNoteCreatedBy')}</Label>
              <Field
                id="saNoteCreatedBy"
                name="saNote.createdBy"
                ref={register({
                  required: saNoteCreatedBy.isMandatory,
                  pattern: Utils.stringToRegex(saNoteCreatedBy?.regex),
                  validate: (value) => Utils.doesNotContainOnlySpaces(value)
                })}
                disabled={!saNoteCreatedBy.isEditable}
              />
            </NameComponentWrapper>
          )}
          {saNoteContent?.isAuthorized && (
            <NameComponentWrapper>
              <Label htmlFor="saNoteContent">{t('dcc.saNoteContent')}</Label>
              <Field
                id="saNoteContent"
                name="saNote.content"
                ref={register({
                  required: saNoteContent.isMandatory,
                  pattern: Utils.stringToRegex(saNoteContent?.regex),
                  validate: (value) => Utils.doesNotContainOnlySpaces(value)
                })}
                disabled={!saNoteContent.isEditable}
              />
            </NameComponentWrapper>
          )}
        </>
      )}
    </ConnectForm>
  );
};
