import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import Caret from '@assets/img/icons/Caret';
import Utils from '@utils/utils';
import Dropdown from '@common/components/dropdown/Dropdown';
import { Select } from '@common/components/dropdown/dropdown.style';
import mp from '@services/mixpanel/mixpanel.service';
import { useTranslation } from '@services/hooks';
import { Language, TYPE_DROPDOWN } from '@/types';
import {
  fetchStoreConfiguration,
  updateErrorTypeOnError,
  updateFieldsConfiguration,
  updateIsLoading,
  updateLegalConfigurationConsentComponent,
  updateLightLabels,
  updatePrivacyConf,
} from '@/littleStateMachine/actions';
import api from '@/api';
import { FieldsTypeEnum } from '@/types/api';

type LanguagesDropdownProps = {
  languages: Array<Language>,
  type: string,
}

const LanguagesDropdown = ({ languages, type }: LanguagesDropdownProps) => {
  const { actions, state } = useStateMachine({
    fetchStoreConfiguration,
    updatePrivacyConf,
    updateIsLoading,
    updateErrorTypeOnError,
    updateLightLabels,
    updateFieldsConfiguration,
    updateLegalConfigurationConsentComponent
  });
  const { customer, isLoading, brand, translations, fieldsConfiguration, featuresActivated, prospectSource } = state;
  const { token } = useParams<{ token: string }>();

  // Prepare navigation
  const navigate = useNavigate();

  const t = useTranslation();
  const [defaultLanguageName, setDefaultLanguageName] = useState('');

  const openLanguages = async () => {
    mp.openLanguages();
  };

  const changeLanguage = async (newLanguage: string) => {
    try {
      actions.updateIsLoading(true);
      // Fetch the light configuration
      const { data: payload } = await api.fetchConf(token, newLanguage);
      actions.updateLightLabels(payload);
      if (featuresActivated.IS_FIELD_CONFIGURATION_GET_FROM_WIRE_API) {
        // Get only translated fields from Wire API
        const fields = fieldsConfiguration.filter((fieldConfiguration) => fieldConfiguration.type === FieldsTypeEnum.LIST_OF_GENDERS || fieldConfiguration.type === FieldsTypeEnum.LIST_OF_VALUES)
          .map((fieldConfiguration) => fieldConfiguration.name);
        const { data: fieldsConfigurationResponse } = await api.getFields(newLanguage, customer.customerContact.address.country, token, fields);
        if (!Utils.checkFieldComponent(fieldsConfigurationResponse.fieldsResponse.meta.names, Object.values(fields) as string[])) {
          throw new TypeError('Missing fields received from wire api');
        }
        actions.updateFieldsConfiguration(fieldsConfigurationResponse.fieldsResponse.data.fieldComponents);
      }
      // Setup html tag language value
      if (payload.i18n.browserCode) {
        window.document.documentElement.lang = payload.i18n.browserCode;
      }
      if (type === TYPE_DROPDOWN.PRIVACIES) {
        // Fetch the privacy configuration
        const { customerContact: { address } } = customer;
        if (featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API) {
          // Update the current language for privacy page
          const { data: legalConfPayload } = await api.getLegalConf(newLanguage, address.country, address.state ?? '', token);
          actions.updateLegalConfigurationConsentComponent(legalConfPayload);
        } else {
          const { data: privacyPayload } = await api.fetchPrivacyConf(token, address.country, address.state, newLanguage);
          // Update the current language for privacy page
          actions.updatePrivacyConf(privacyPayload);
        }
      }
      actions.updateIsLoading(false);
      mp.changeLanguage(newLanguage);
    } catch (err) {
      actions.updateErrorTypeOnError(err.response.data);
      navigate('/error');
    }
  };

  useEffect(() => {
    let defaultLang: Language | undefined;
    if (type === TYPE_DROPDOWN.PRIVACIES) {
      defaultLang = languages.find((lang) => lang.code === translations.privacyLanguageCode);
    } else {
      defaultLang = languages.find((lang) => lang.code === translations.lightLanguageCode);
    }
    if (defaultLang) {
      setDefaultLanguageName(defaultLang.name);
    }
  }, [languages, translations.lightLanguageCode, translations.privacyLanguageCode]);

  return (
    <Dropdown type={type}>
      {brand.code && brand.code === 'BAL' && <span>{t('dcc.language')}</span>}
      <Select
        id="languageDropdown"
        name="languageDropdown"
        onClick={openLanguages}
        onChange={(event) => changeLanguage(event.target.value)}
        defaultValue=""
        type={type}
      >
        <option value={type === TYPE_DROPDOWN.PRIVACIES ? translations.privacyLanguageCode : translations.lightLanguageCode} key={type === TYPE_DROPDOWN.PRIVACIES ? translations.privacyLanguageCode : translations.lightLanguageCode}>{Utils.toTitleCase(defaultLanguageName)}</option>
        { languages.map((lang) => (
          type === TYPE_DROPDOWN.PRIVACIES
            ? lang.code !== translations.privacyLanguageCode && <option value={lang.code} key={lang.code}>{Utils.toTitleCase(lang.name)}</option>
            : lang.code !== translations.lightLanguageCode && <option value={lang.code} key={lang.code}>{Utils.toTitleCase(lang.name)}</option>
        ))}
      </Select>
      <Caret type={type} className={isLoading ? 'loader' : 'none'} />
    </Dropdown>
  );
};

export default LanguagesDropdown;
