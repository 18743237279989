import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import Utils from '@utils/utils';
import Player from '@pages/Registered/components/Player';
import Carousel from '@pages/Registered/components/Carousel';
import { StyledEventText, StyledLogo, TextWrapper } from '@pages/Registered/registered.styles';
import RegisteredService from '@pages/Registered/registered.service';
import { SetupDisplayFromProspectSource } from '@pages/Registered/components/SetupDisplayFromProspectSource';
import { RegisteredText } from '@pages/Registered/components/RegiteredText';
import VideoPlayer from '@pages/Registered/components/VideoPlayer';
import TextComponentContainer from '@pages/Registered/components/TextComponentContainer';
import { DesktopBackgroundContainer } from '@pages/Welcome/welcome.styles';
import moment from 'moment';
import { useBackgroundLoading } from '@services/hooks/useBackgroundLoading';
import { SLAppDownloadButton } from '@pages/Registered/components/SLAppDownloadButton';
import CtaOrWallpaperButtonOrNothing from '@pages/Registered/components/CtaOrWallpaperButtonOrNothing';
import RedirectUrlButton from '@pages/Registered/components/RedirectUrlButton';
import { ImgLogo } from '@/common/components';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import { Customer, isPartialUpdateProspectSource, PreferredMethodOfContact } from '@/types';
import { FullPageLoader } from '@/common/components/Loader';
import {
  cleanCustomerData,
  resetBackground,
  resetCustomerData,
  updateBackground,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateIsPoPAlreadySent,
} from '@/littleStateMachine/actions';
import { CarouselImg } from '@/types/form/carouselImg';
import { PageID } from '@/types/enum/pageID';
import api from '@/api';
import { ThemeType } from '@/types/enum/themeType';
import { BackgroundEnum } from '@/types/enum/background';
import { EventKey } from '@/types/enum/eventKey';
import { BrandContainer } from '@/common/components/containers';
import { emptyCustomer } from '@/littleStateMachine/state';
import OtpForm from '@/common/components/form/OtpForm';
import { contactMethods } from '@/types/enum/contactMethods.enum';
import mp from '@/services/mixpanel/mixpanel.service';
import { ErrorTypeMixPanel } from '@/types/brandedTokenError';
import { BVCRAFT_GIFT_FLOW, BVCRAFT_WHLSLR_FLOW } from '@/types/enum/certificateOfCraftPage';
import { IResponseLineQrCodes } from '@/types/api';

const Registered: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.REGISTERED);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { actions, state } = useStateMachine({ updateBackground, resetBackground, cleanCustomerData, resetCustomerData, updateErrorTypeOnError, updateCustomerInformation, updateIsPoPAlreadySent });
  const { actualToken, brand, prospectSource, event, flow, translations, backgrounds, alreadyRegistered, featuresActivated, isPoPAlreadySent, customer, privacy, salesAdvisor } = state;
  const { translationKeySuffixComponent, musics, carouselComponent, videoComponent, textComponent, eventRedirectLink, eventId } = event;
  const { code, defaultLogo, style: { colors } } = brand;
  const { customerContact: { socialAccounts: { okta } } } = customer;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const certificateOfCraftFlow = featuresActivated.IS_CERTIFICATE_OF_CRAFT_WHOLESALER_FLOW_ACTIVATED ? BVCRAFT_WHLSLR_FLOW : BVCRAFT_GIFT_FLOW;
  useThemeColor(brand, ThemeType.WELCOME);

  // use right translationKey
  const translationKey = Utils.overloadTranslationKey(translationKeySuffixComponent, EventKey.REGISTERED_KEY);
  const shouldDisplayRegisteredText = Utils.shouldDisplayText(translationKeySuffixComponent, EventKey.REGISTERED_KEY, true, alreadyRegistered);

  // current page
  const localStep = PageID.REGISTERED;

  // Retrieve token from the URL
  const token = useParams<{ token: string }>().token ?? '';

  // local State
  const [isLoading, setIsLoading] = useState(true);
  const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [lineQrCode, setLineQrCode] = useState('');
  const [weChatQrCode, setWeChatQrCode] = useState('');
  const [visibilityAudioText, setVisibilityAudioText] = useState('visible');
  const [activeCertificateOfCraftPage, setActiveCertificateOfCraftPage] = useState(certificateOfCraftFlow[0]);
  const [customerEventId, setCustomerEventId] = useState('');
  // to locally save some data to display before reset customer data
  const [staticCustomer, setStaticCustomer] = useState<Customer>(emptyCustomer);
  const [otpVerified, setOtpVerified] = useState<boolean>(false);
  const [lineQrCodes, setLineQrCodes] = useState<IResponseLineQrCodes>();

  // custom hooks
  const loadBackgrounds = useBackgroundLoading(setIsBackgroundLoading, backgrounds?.registeredBackground?.backgroundUrl, backgrounds?.registeredDesktopBackground?.backgroundUrl);

  const otpTextPrefix = staticCustomer.customerContact.preferred === PreferredMethodOfContact.PHONE ? contactMethods.SMS : contactMethods.EMAIL;

  useEffect(() => {
    mp.pageView(localStep);
    (async function fetch() {
      if (featuresActivated.IS_REGISTERED_PAGE_SKIPPED && featuresActivated.IS_SOCIAL_BOOKING_TOOL_USED) {
        setIsLoading(true);
      }
      actions.resetBackground(BackgroundEnum.currentBackground);
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        if (brand.backgrounds?.registeredPage?.backgroundUrl || brand.backgrounds?.registeredPage?.backgroundColor) {
          actions.updateBackground({
            background: brand.backgrounds.registeredPage,
            backgroundName: BackgroundEnum.currentBackground,
          });
        }
        if (brand.desktopBackgrounds?.registeredPage?.backgroundUrl || brand.desktopBackgrounds?.registeredPage?.backgroundColor) {
          actions.updateBackground({
            background: brand.desktopBackgrounds.registeredPage,
            backgroundName: BackgroundEnum.currentDesktopBackground,
          });
        }
        await loadBackgrounds(brand.backgrounds?.registeredPage?.backgroundUrl, brand.desktopBackgrounds?.registeredPage?.backgroundUrl);
        // upsert customer on old flow but only if customer is new (to handle a customJourney in old flow - should not register client at the end, only update the dep store card in checkCustomer request)
        if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED || !alreadyRegistered || isPartialUpdateProspectSource(prospectSource)) {
          try {
            const customerData = {
              ...state.customer,
              language: translations.privacyLanguageCode, // push the privacy language even if customer.language is another language (application language)
              registrationDateInStore: moment().utc().format(),
              registrationSACode: state.salesAdvisor?.vendorId,
            };
            actions.cleanCustomerData(undefined);
            let isOktaCustomerCreated = false;
            try {
              if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && !alreadyRegistered && !okta) {
                const { data } = await api.createOktaCustomer(customerData, token);
                // store okta id to send it in validateOtp request
                actions.updateCustomerInformation({ okta: data.oktaLogin });
                isOktaCustomerCreated = true;
              }
            } catch (err) {
              actions.updateErrorTypeOnError(err.response.data);
              mp.errorTriggered(ErrorTypeMixPanel.OKTA_CREATION_FAILED);
              setOtpVerified(true);
            }
            const { data } = await api.upsertCustomer(customerData, token);
            setCustomerEventId(data.eventId || '');
            // todo: add mixpanel track on legalInformation if featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API === true
            RegisteredService.saveRegistrationCompletedEvent(customer, privacy, isOktaCustomerCreated, alreadyRegistered, salesAdvisor);
            RegisteredService.locallySaveCustomerData(staticCustomer, setStaticCustomer, customerData);
            // delete customer data for confidentiality, deactivated for Wire edit for now
            if (!featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED) {
              actions.resetCustomerData(false);
            }
            if (featuresActivated.IS_CERTIFICATE_OF_CRAFT_WHOLESALER_FLOW_ACTIVATED) {
              const { data: { alreadyExists } } = await api.checkPoP(actualToken);
              actions.updateIsPoPAlreadySent(alreadyExists);
            }
            setIsLoading(false);
          } catch (err) {
            if (err.response) {
              actions.updateErrorTypeOnError(err.response.data);
            }
            navigate('/error');
          }
        } else {
          setIsLoading(false);
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, []);

  useEffect(() => {
    (async function fetch() {
      if (!isLoading) {
        if (featuresActivated.IS_LINE_QRCODE_DISPLAYED) {
          await RegisteredService.tryToFetchAndSetQrCode(api.fetchLineQrCode, setLineQrCode, token);
        }
        if (featuresActivated.IS_WECHAT_QRCODE_DISPLAYED || featuresActivated.IS_WECHAT_MODAL_DISPLAYED) {
          await RegisteredService.tryToFetchAndSetQrCode(api.fetchWeChatQrCode, setWeChatQrCode, token);
        }
        if (event.isCustomJourney && !featuresActivated.IS_REGISTERED_PAGE_SKIPPED) {
          if (carouselComponent) {
            const updatePromises: Promise<string>[] = [];
            carouselComponent.forEach((media: CarouselImg) => updatePromises.push(Utils.loadImage(media.imageUrl)));
            await Promise.all(updatePromises);
          }
          if (eventRedirectLink) {
            setTimeout(async () => {
              document.location.href = eventRedirectLink;
            }, 3000);
          }
          setImagesLoaded(true);
        }
        if (featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED) {
          const qrCodes = await api.fetchLineJpThTwQrCodes(actualToken);
          setLineQrCodes(qrCodes.data);
        }
      }
    }());
  }, [isLoading]);

  useEffect(() => {
    (async function fetch() {
      if (featuresActivated.IS_REGISTERED_PAGE_SKIPPED && featuresActivated.IS_SOCIAL_BOOKING_TOOL_USED && eventRedirectLink) {
        if (eventId || customerEventId) {
          setTimeout(() => {
            document.location.href = `${eventRedirectLink}${eventId || customerEventId}`;
          }, 1000);
        }
      }
    }());
  }, [customerEventId]);

  if (code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{
        colors,
        brand: brand.code,
        prospectSource,
        background: backgrounds.currentBackground,
        language: translations.lightLanguageCode,
        hasDesktopBackground: Boolean(backgrounds.currentDesktopBackground?.backgroundUrl) && Boolean(backgrounds.currentDesktopBackground?.backgroundPosition),
        isOnRegisteredPage: true,
        desktopBackground: backgrounds.currentDesktopBackground
      }}
      >
        {!isLoading && (imagesLoaded || !event.isCustomJourney) && !isBackgroundLoading
          ? (
            <DesktopBackgroundContainer
              id="registeredDesktopBackgroundContainer"
              desktopBackground={backgrounds.currentDesktopBackground}
              prospectSource={prospectSource}
            >
              <BrandContainer>
                <StyledLogo><ImgLogo src={defaultLogoUrl} alt="Logo" id="brandLogo" /></StyledLogo>
                <TextWrapper>
                  <RegisteredText
                    staticCustomer={staticCustomer}
                    otpVerified={otpVerified}
                    activeCertificateOfCraftPage={activeCertificateOfCraftPage}
                  />
                </TextWrapper>
                {event.isCustomJourney && shouldDisplayRegisteredText && (
                  <StyledEventText visibility={visibilityAudioText} id="registeredLabel">
                    {t(`web.pin.registered.${translationKey}`)}
                  </StyledEventText>
                )}
                {featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && !alreadyRegistered && !otpVerified && (
                  <OtpForm isOnRegisteredPage setOtpVerified={setOtpVerified} otpTextPrefix={otpTextPrefix} />)}
                {featuresActivated.IS_SL_APP_CTA_DISPLAYED && featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && (alreadyRegistered || otpVerified) && (
                  <SLAppDownloadButton />
                )}
                {/* todo: delete after end of Japan Wire customization ALW-2129 */}
                {featuresActivated.IS_JP_CUSTOM_CAROUSEL_ACTIVATED && brand?.jpCustomCarousel && (
                  <CtaOrWallpaperButtonOrNothing
                    token={token}
                    event={event}
                    isJpCustomCarouselActivated
                    jpCustomCarousel={brand.jpCustomCarousel}
                  />
                )}
                {featuresActivated.IS_REDIRECT_URL_ACTIVATED && brand?.redirectUrl && (
                  <RedirectUrlButton redirectUrl={brand.redirectUrl} />
                )}
                {(!featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED || (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && otpVerified && !alreadyRegistered)) && (
                  <>
                    <SetupDisplayFromProspectSource
                      lineQrCode={lineQrCode}
                      setLineQrCode={setLineQrCode}
                      weChatQrCode={weChatQrCode}
                      setWeChatQrCode={setWeChatQrCode}
                      staticCustomer={staticCustomer}
                      lineQrCodes={lineQrCodes}
                      isPoPAlreadySent={isPoPAlreadySent}
                      actualToken={actualToken}
                      updateErrorTypeOnError={actions.updateErrorTypeOnError}
                      certificateOfCraftPage={activeCertificateOfCraftPage}
                      setActiveCertificateOfCraftPage={setActiveCertificateOfCraftPage}
                      isCertificateOfCraftWholesalerActivated={featuresActivated.IS_CERTIFICATE_OF_CRAFT_WHOLESALER_FLOW_ACTIVATED}
                      setIsLoading={setIsLoading}
                    />
                    {textComponent && Boolean(textComponent.length)
                        && <TextComponentContainer textComponent={textComponent} />}
                    {musics && Boolean(musics.length)
                        && <Player tracks={musics} setVisibilityAudioText={setVisibilityAudioText} />}
                    {carouselComponent && Boolean(carouselComponent.length)
                        && <Carousel images={carouselComponent} token={token} />}
                    {videoComponent && Boolean(videoComponent.length) && <VideoPlayer video={videoComponent} />}
                  </>
                )}
              </BrandContainer>
            </DesktopBackgroundContainer>
          )
          : <FullPageLoader />}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default Registered;
