import React from 'react';

const ProfileIcon = () => (
  <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="02 Glyphs / 22 / Profile">
      <path
        id="Profile"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.300049 10.9998C0.300049 5.09036 5.0906 0.299805 11 0.299805C16.9095 0.299805 21.7 5.09036 21.7 10.9998C21.7 16.9093 16.9095 21.6998 11 21.6998C5.0906 21.6998 0.300049 16.9093 0.300049 10.9998ZM20.3 10.9998C20.3 5.86356 16.1363 1.6998 11 1.6998C5.8638 1.6998 1.70005 5.86356 1.70005 10.9998C1.70005 13.6746 2.82927 16.0857 4.63699 17.7823C4.68337 15.2857 7.51444 13.2725 10.9998 13.2725C14.4857 13.2725 17.3167 15.2857 17.3631 17.7823C19.1708 16.0857 20.3 13.6746 20.3 10.9998ZM11 20.2998C12.825 20.2998 14.5273 19.7741 15.9636 18.8659L15.9637 17.8445C15.9637 16.2605 13.95 14.7659 11.2545 14.6767L10.9998 14.6725C8.26335 14.6725 6.16682 16.1192 6.04227 17.6963L6.03641 17.8445V18.8659C7.47273 19.7741 9.17499 20.2998 11 20.2998ZM11 3.93617C8.60507 3.93617 6.66369 5.87747 6.66369 8.27253C6.66369 10.6673 8.60521 12.6089 11 12.6089C13.3949 12.6089 15.3364 10.6673 15.3364 8.27253C15.3364 5.87747 13.395 3.93617 11 3.93617ZM11 5.33617C12.6218 5.33617 13.9364 6.65069 13.9364 8.27253C13.9364 9.8941 12.6217 11.2089 11 11.2089C9.37842 11.2089 8.06369 9.8941 8.06369 8.27253C8.06369 6.65069 9.37825 5.33617 11 5.33617Z"
        fill="black"
      />
    </g>
  </svg>

);

export default ProfileIcon;
