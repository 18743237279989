import React from 'react';
import { useStateMachine } from 'little-state-machine';
import { jwtDecode } from 'jwt-decode';
import AppleSignInButton from 'react-apple-signin-auth';
import FlowService from '@services/flow.service';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from '@services/hooks';
import AppleIcon from '@assets/img/icons/AppleIcon';
import SocialLoginButton from '@pages/CountryPage/components/SocialLoginButtons/SocialLoginButton';
import mp from '@services/mixpanel/mixpanel.service';
import { updateCustomerInformation, updateIsSocialUserRetrieved } from '@/littleStateMachine/actions';
import { PageID } from '@/types/enum/pageID';
import { AppleSocialLoginResponse, AppleUser } from '@/types/apple';
import { InformationAction } from '@/types';
import { mixpanelSocialUsed } from '@/types/enum/mixpanel.enum';

interface Props {
  localStep: PageID,
}

/**
 * To sign in with apple, an https redirect url is necessary.
 * Therefore on local env, we need to setup a proxy with an associated domain owned by ngrock :
 * https://dashboard.ngrok.com/domains
 * @param localStep
 * @constructor
 */

const AppleButton: React.FC<Props> = ({ localStep }) => {
  const { state, actions } = useStateMachine({ updateCustomerInformation, updateIsSocialUserRetrieved });
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const t = useTranslation();
  const { appleClientId } = state;

  const onSuccess = async (response: AppleSocialLoginResponse) => {
    mp.clickSocialLogin(mixpanelSocialUsed.APPLE);
    prefillWithAppleData(actions.updateCustomerInformation, response);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  };

  const prefillWithAppleData = (updateCustomerInfos: (payload: InformationAction) => void, response: AppleSocialLoginResponse) => {
    const { authorization: { id_token } } = response;
    const appleUser: AppleUser = jwtDecode(id_token);

    const { email, email_verified, sub, is_private_email } = appleUser;
    const firstName = response.user?.name.firstName;
    const lastName = response.user?.name.lastName;

    const user: InformationAction = {
      email,
      apple: {
        userId: sub,
        emailVerified: email_verified,
        isPrivateEmail: is_private_email
      },
      ...(firstName && { firstName }),
      ...(lastName && { lastName })
    };
    updateCustomerInfos(user);
    actions.updateIsSocialUserRetrieved(true);
  };

  return (
    <AppleSignInButton
      authOptions={{
        clientId: appleClientId,
        scope: 'email name',
        redirectURI: window.location.origin,
        usePopup: true,
      }}
      skipScript={false}
      onSuccess={onSuccess}
      onError={(_: any) => {}}
      uiType="dark"
      render={(props: any) => (
        <SocialLoginButton {...props} label={t('web.continueWithApple')} logo={<AppleIcon />} />
      )}
    />
  );
};

export default AppleButton;
