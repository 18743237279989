export enum TextFieldComponentEnum {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  FURIGANA_FIRST_NAME = 'FURIGANA_FIRST_NAME',
  FURIGANA_LAST_NAME = 'FURIGANA_LAST_NAME',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  STREET = 'STREET',
  CITY = 'CITY',
  POBOX = 'POBOX',
  ZIPCODE = 'ZIPCODE',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK= 'TIKTOK',
  MIDDLE_NAME = 'MIDDLE_NAME',
  COMPANY = 'COMPANY',
  CUST_TYPE = 'CUST_TYPE',
  NOTE_SA_ID = 'NOTE_SA_ID',
  NOTE = 'NOTE',
  CUST_SINCE_DATE = 'CUST_SINCE_DATE',
  NOTE_CREATED_DATE = 'NOTE_CREATED_DATE',
  BUILDING = 'BUILDING',
  BLOCK = 'BLOCK',
  FLOOR_APT_NB ='FLOOR_APT_NB'
}

export enum DateFieldComponentEnum {
  BIRTH_DATE = 'BIRTH_DATE',
}

export enum ValuesListFieldComponentEnum {
  COUNTRY = 'COUNTRY',
  PHONE_PREFIX = 'PHONE_PREFIX',
  PHONE_TYPE = 'PHONE_TYPE',
  NATIONALITY = 'NATIONALITY',
  AUSTRALIA_STATES = 'AUSTRALIA_STATES',
  BRAZIL_STATES = 'BRAZIL_STATES',
  CANADA_STATES = 'CANADA_STATES',
  CHINESE_MAINLAND_STATES = 'CHINESE_MAINLAND_STATES',
  INDIA_STATES = 'INDIA_STATES',
  JAPAN_STATES = 'JAPAN_STATES',
  MEXICO_STATES = 'MEXICO_STATES',
  NEW_ZEALAND_STATES = 'NEW_ZEALAND_STATES',
  UNITED_STATES_STATES = 'UNITED_STATES_STATES',
  TURKEY_STATES = 'TURKEY_STATES',
  ITALY_STATES = 'ITALY_STATES',
  CHILE_STATES = 'CHILE_STATES',
  LITHUANIA_STATES = 'LITHUANIA_STATES',
  SPAIN_STATES = 'SPAIN_STATES',
  THAILAND_STATES = 'THAILAND_STATES',
  VIET_NAM_STATES = 'VIET_NAM_STATES',
  COLOMBIA_STATES = 'COLOMBIA_STATES',
  UKRAINE_STATES = 'UKRAINE_STATES',
  LEBANON_STATES = 'LEBANON_STATES',
  PANAMA_STATES = 'PANAMA_STATES',
  IRELAND_STATES = 'IRELAND_STATES',
  MALAYSIA_STATES = 'MALAYSIA_STATES',
  TAIWAN_REGION_STATES = 'TAIWAN_REGION_STATES',
  ADDRESS_TYPE = 'ADDRESS_TYPE'
}

export enum LanguageListFieldComponentEnum {
  LANGUAGE = 'LANGUAGE',
}

export enum GenderListFieldComponentEnum {
  GENDER = 'GENDER',
}

export enum FieldComponentUpdateNewPosCustomerPageEnum {
  LANGUAGE = LanguageListFieldComponentEnum.LANGUAGE,
  GENDER = GenderListFieldComponentEnum.GENDER,
  FIRST_NAME = TextFieldComponentEnum.FIRST_NAME,
  MIDDLE_NAME = TextFieldComponentEnum.MIDDLE_NAME,
  LAST_NAME = TextFieldComponentEnum.LAST_NAME,
  EMAIL = TextFieldComponentEnum.EMAIL,
  BIRTH_DATE = DateFieldComponentEnum.BIRTH_DATE,
  CUST_SINCE_DATE = TextFieldComponentEnum.CUST_SINCE_DATE,
  COMPANY = TextFieldComponentEnum.COMPANY,
  CUST_TYPE = TextFieldComponentEnum.CUST_TYPE,
  NOTE = TextFieldComponentEnum.NOTE,
  NOTE_CREATED_DATE = TextFieldComponentEnum.NOTE_CREATED_DATE,
  NOTE_SA_ID = TextFieldComponentEnum.NOTE_SA_ID,
  ADDRESS_TYPE = ValuesListFieldComponentEnum.ADDRESS_TYPE,
  BUILDING = TextFieldComponentEnum.BUILDING,
  BLOCK = TextFieldComponentEnum.BLOCK,
  FLOOR_APT_NB = TextFieldComponentEnum.FLOOR_APT_NB,
  COUNTRY = ValuesListFieldComponentEnum.COUNTRY,
  CITY = TextFieldComponentEnum.CITY,
  STREET = TextFieldComponentEnum.STREET,
  ZIPCODE = TextFieldComponentEnum.ZIPCODE,
  PHONE_NUMBER = TextFieldComponentEnum.PHONE_NUMBER,
  PHONE_PREFIX = ValuesListFieldComponentEnum.PHONE_PREFIX,
  PHONE_TYPE = ValuesListFieldComponentEnum.PHONE_TYPE
}

export type FieldComponentEnum = GenderListFieldComponentEnum | LanguageListFieldComponentEnum | ValuesListFieldComponentEnum | DateFieldComponentEnum | TextFieldComponentEnum;
