import { StyledField } from '@pages/Address/address.styles';
import regex from '@utils/regex';
import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import UpdateAddressService from '@pages/UpdateAddress/updateAddress.service';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Country, ProspectSource } from '@/types';
import { OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form';

type ZipCodeProps = {
  prospectSource: ProspectSource,
  currentCountry?: Country,
  showZipCodeError?: boolean,
  setShowZipCodeError?: Dispatch<SetStateAction<boolean>>,
}

export const ZipCode: React.FC<ZipCodeProps> = ({ prospectSource, currentCountry, setShowZipCodeError, showZipCodeError }) => (
  <ConnectForm>
    {({ register, customer, errors, t }: UseFormMethods & GlobalState & TranslationFunction) => (
      <>
        <Label htmlFor="zipCode">{t('dcc.zipCode')}</Label>
        {(prospectSource !== ProspectSource.UPDATE_ADDRESS || !currentCountry?.checkZipCode) && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
        <StyledField
          name="zipCode"
          id="zipCode"
          ref={register(currentCountry?.checkZipCode ? {
            pattern: currentCountry?.code === 'TW' ? regex.zipcodeCheckTW : regex.zipcodeCheck,
            required: prospectSource === ProspectSource.UPDATE_ADDRESS,
          } : {
            pattern: regex.zipCode,
          })}
          defaultValue={customer.customerContact.address.zipCode}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'postal-code'}
          hasError={showZipCodeError && errors?.zipCode?.type}
          onChange={() => setShowZipCodeError?.(false)}
          onBlur={(event) => setShowZipCodeError && UpdateAddressService.onBlur(setShowZipCodeError, event)}
          maxLength={30}
        />
      </>
    )}
  </ConnectForm>
);
