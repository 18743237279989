import Utils from '@utils/utils';
import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { ProspectSource } from '@/types';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

type Props = {
  prospectSource: ProspectSource;
  firstName: TextFieldComponent;
}

export const FirstName: React.FC<Props> = ({ prospectSource, firstName }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="firstName">{t('dcc.firstname')}</Label>
        <Field
          name="firstName"
          id="firstName"
          ref={register({ required: firstName.isMandatory,
            pattern: Utils.stringToRegex(firstName.regex),
            validate: (value) => Utils.doesNotContainOnlySpaces(value),
          })}
          defaultValue={customer.customerInfo.firstname}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'given-name'}
          disabled={!firstName.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
