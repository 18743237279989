import Utils from '@utils/utils';
import React from 'react';
import { StyledSelect } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { GenderListFieldComponent } from '@/types/api';

type Props = {
  gender: GenderListFieldComponent,
}

export const CivilTitle: React.FC<Props> = ({ gender }) => (
  <ConnectForm>
    {({ register, customer, brand, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { customerContact: { address: { country } } } = customer;
      const { code } = brand;

      return (
        <>
          <Label htmlFor="title">{Utils.isGenderDisplayedInsteadOfSalutation(country, code) ? t('gender.title') : t('dcc.salutation')}</Label>
          <StyledSelect
            name="title"
            id="title"
            parentRef={register({ required: gender.isMandatory })}
            defaultValue={customer.customerInfo.title}
            autoComplete="honorific-prefix"
            disabled={!gender?.isEditable}
          >
            <option value="">-</option>
            {gender?.listOfValues?.map((courtesy) => (
              <option value={courtesy.value} key={courtesy.value}>{courtesy.label}</option>
            ))}
          </StyledSelect>
        </>
      );
    }}
  </ConnectForm>
);
