import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { TextAndButtonToDisplay } from '@pages/Welcome/components/TextAndButtonToDisplay';
import { DesktopBackgroundContainer } from '@pages/Welcome/welcome.styles';
import WelcomeService from '@pages/Welcome/welcome.service';
import GifModal from '@pages/Welcome/components/GifModal';
import { useBackgroundLoading } from '@services/hooks/useBackgroundLoading';
import { lineApiService } from '@services/line-api.service';
import {
  fetchStoreConfiguration,
  resetStore,
  updateBackground,
  updateBrandOnError,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateIsLoading,
  updateMixPanelToken,
  updatePrefilledClientData,
  updateSaleAdvisor,
  updateToken
} from '@/littleStateMachine/actions';
import api from '@/api';
import { BrandContainer } from '@/common/components/containers';
import { useLastPageVisited, useScrollToTop, useThemeColor } from '@/services/hooks';
import { FullPageLoader } from '@/common/components/Loader';
import Config from '@/config';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { ThemeType } from '@/types/enum/themeType';

const Welcome: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.WELCOME);

  // Prepare navigation
  const navigate = useNavigate();

  // Current page
  const localStep = PageID.WELCOME;

  // Retrieve current state from store
  const { actions, state } = useStateMachine({
    updatePrefilledClientData,
    updateBackground,
    fetchStoreConfiguration,
    updateErrorTypeOnError,
    updateBrandOnError,
    updateToken,
    resetStore,
    updateCustomerInformation,
    updateIsLoading,
    updateMixPanelToken,
    updateSaleAdvisor
  });

  const { brand, prospectSource, actualToken, translations, backgrounds, event, isLoading, featuresActivated, customer, salesAdvisor } = state;
  const { customerContact: { socialAccounts: { line } } } = customer;
  const { gifComponent } = event;
  const { code, style: { colors } } = brand;
  useThemeColor(brand, ThemeType.WELCOME);
  // Retrieve token from the URL
  const urlParams = new URLSearchParams(document.location.search);
  const lineCode = urlParams.get('lineCode');
  const lineRedirectUri = `${document.location.origin}/line`;
  let token = useParams<{ token: string }>().token ?? '';
  const unionId = urlParams.get('UnionId') ?? '';
  const openId = urlParams.get('OpenId') ?? '';
  const justPoseId = urlParams.get('JustPoseId') ?? '';
  const language = urlParams.get('language') ?? '';
  // Unix timestamp format
  const weChatLastSubscriptionDate = urlParams.get('SubscribeTime') ?? '';
  // local states
  const [modal, setModal] = useState({ open: true });
  const [toggleFadeOut, setToggleFadeOut] = useState(false);

  const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);

  const loadBackgrounds = useBackgroundLoading(setIsBackgroundLoading, backgrounds?.welcomeBackground?.backgroundUrl, backgrounds?.welcomeDesktopBackground?.backgroundUrl);

  useEffect(() => {
    (async function fetch() {
      actions.updateIsLoading(true);
      const languageToSend = language || translations.lightLanguageCode || navigator.language;
      // If it's a new registration we clean the data session
      WelcomeService.prepareForNewRegistration(actions.resetStore, actions.updateToken, token, actualToken);
      try {
        // Check if token is static
        if (token && ([Config.eventPrefix].includes(token[0]))) {
          token = await WelcomeService.handleStaticToken(actions.resetStore, token, navigate, justPoseId, language);
        }
        // Add weChat information to the dynamic token
        if (openId || unionId) {
          await api.addWeChatSocialChannelToDynamicToken(token, { weChat: { userId: openId, weChatLastSubscriptionDate }, weChatPlatform: unionId });
        }
        const { data: payload } = await api.fetchConf(token, languageToSend);
        // Setup html tag language value
        await WelcomeService.loadConfAndSetLanguage(actions.fetchStoreConfiguration, payload);
        // handle prefilled data
        WelcomeService.handlePrefilledData(actions.updatePrefilledClientData, payload);
        // handle brand backgrounds overloaded in BO
        await WelcomeService.handleCustomBackgrounds(actions.updateBackground, payload);
        await loadBackgrounds(payload.brand.backgrounds?.welcomePage?.backgroundUrl, payload.brand.desktopBackgrounds?.welcomePage?.backgroundUrl);
        WelcomeService.sendMpWelcomeEvent(payload, token);
        actions.updateMixPanelToken(payload.mixPanelToken);
        actions.updateIsLoading(false);
        if (lineCode && !line) {
          const lineUser = await lineApiService.retrieveLineUser(token, lineCode, lineRedirectUri, payload.brand.code, payload.store.countryCode);
          WelcomeService.prefillWithLineData(actions.updateCustomerInformation, lineUser);
        }
        // If prospectSource is DCC, fill chosen SA and keep SA info in the storage session
        if (featuresActivated.IS_PREFERRED_SA_ACTIVATED) {
          if (state.salesAdvisor) {
            actions.updateSaleAdvisor(state.salesAdvisor);
            window.sessionStorage.setItem('__LSM__', JSON.stringify(state));
          } else {
            const sessionStored = JSON.parse(window.sessionStorage.getItem('__LSM__')!);
            delete sessionStored.salesAdvisor;
            window.sessionStorage.setItem('__LSM__', JSON.stringify(sessionStored));
          }
        }
      } catch (err) {
        WelcomeService.handleError(err, actions.updateBrandOnError, actions.updateErrorTypeOnError, actions.fetchStoreConfiguration, code, token);
        navigate('/error');
      }
    }());
  }, [navigate]);

  // do not display welcome page for Luce pp source
  if (code) {
    if (isLoading || isBackgroundLoading) {
      return (
        <ThemeProvider theme={{ colors, brand: code, prospectSource, background: backgrounds.currentBackground, desktopBackground: backgrounds.currentDesktopBackground, language: translations.lightLanguageCode, hasDesktopBackground: Boolean(backgrounds.currentDesktopBackground?.backgroundUrl) && Boolean(backgrounds.currentDesktopBackground?.backgroundPosition) }}>
          <FullPageLoader />
        </ThemeProvider>
      );
    }
    if (featuresActivated.IS_WELCOME_PAGE_SKIPPED && ![Config.eventPrefix].includes(token[0])) {
      WelcomeService.sendMpStartRegisterEvent(salesAdvisor?.vendorId, featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED);
      return (
        <ThemeProvider theme={{ colors, brand: code, prospectSource, background: backgrounds.currentBackground, desktopBackground: backgrounds.currentDesktopBackground, language: translations.lightLanguageCode, hasDesktopBackground: Boolean(backgrounds.currentDesktopBackground?.backgroundUrl) && Boolean(backgrounds.currentDesktopBackground?.backgroundPosition) }}>
          <Navigate to={`/${token}/${FlowService.nextPage(state, localStep)}`} replace />
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={{ colors, brand: code, prospectSource, background: backgrounds.currentBackground, desktopBackground: backgrounds.currentDesktopBackground, language: translations.lightLanguageCode, hasDesktopBackground: Boolean(backgrounds.currentDesktopBackground?.backgroundUrl) && Boolean(backgrounds.currentDesktopBackground?.backgroundPosition) }}>
        <DesktopBackgroundContainer id="welcomeDesktopBackgroundContainer" desktopBackground={backgrounds.currentDesktopBackground} prospectSource={prospectSource}>
          <BrandContainer>
            { modal.open && gifComponent?.gifUrl && (
            <GifModal id="gifImg" gifComponent={gifComponent} setToggleFadeOut={setToggleFadeOut} toggleFadeOut={toggleFadeOut} setModal={setModal} />
            )}
            <TextAndButtonToDisplay />
          </BrandContainer>
        </DesktopBackgroundContainer>
      </ThemeProvider>
    );
  }
  return <FullPageLoader />;
};

export default Welcome;
