import React, { Dispatch, SetStateAction } from 'react';
import { FieldDate } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import IdentityService from '@pages/Identity/identity.service';
import { GlobalState } from 'little-state-machine';
import { ConnectForm } from '@/common/components/form/index';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { DateFieldComponent } from '@/types/api';

type Props = {
  formMethods: UseFormMethods<InformationFormProps>,
  setShowDateError: Dispatch<SetStateAction<boolean>>,
  showDateError: boolean,
  birthDate: DateFieldComponent
}

export const Day: React.FC<Props> = ({ formMethods, setShowDateError, showDateError, birthDate }) => (
  <ConnectForm>
    {({ register, getValues, customer, errors, trigger, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <div>
        <FieldDate
          name="day"
          id="dayBirth"
          pattern="[0-9]*"
          ref={register({
            required: birthDate.dateInformation.isDayFieldMandatory,
            validate: {
              dateFormatError: () => IdentityService.checkDateFormat(formMethods),
              minimalAgeError: () => IdentityService.checkMinimalAge(formMethods, birthDate.dateInformation.minimalAge),
            },
          })}
          defaultValue={customer.customerInfo.day}
          placeholder={t('web.dd')}
          maxLength={2}
          onKeyDown={(event) => {
            IdentityService.avoidAlphaCharacters(event);
            IdentityService.avoidInputIfTooLong(event, getValues().day, 2);
          }}
          hasError={showDateError && errors.day && errors.day.type !== 'required'}
          onChange={(event) => IdentityService.removeAlphaCharactersAndFocusNextElementOnMaxLength(event, 2, 'day', formMethods, setShowDateError)}
          onBlur={async () => {
            IdentityService.fillMinimalLength('day', getValues().day, 2, formMethods);
            setShowDateError(true);
            // so that day, month, year will always get the same error object if an error occurred on one of them
            await trigger();
          }}
        />
      </div>
    )}
  </ConnectForm>
);
