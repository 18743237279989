export enum LegalConfType {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  UPDATE_MARKETING_CONSENT = 'UPDATE_MARKETING_CONSENT'
}

export enum ConsentComponentType {
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  BUTTON = 'BUTTON'
}
