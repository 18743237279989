import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

export const Button = styled.button`
  display: block;
  width: ${(props) => props?.name?.includes('newPos') ? '80%' : '70%'};
  min-height: 40px;
  margin: 0 auto;
  cursor: pointer;
  font-size: 14pt;
  color: ${(props) => props.theme.colors.COMMON.button.color};
  background-color: ${(props) => props.theme.colors.COMMON.button.backgroundColor};
  border: 1px solid ${(props) => props.theme.colors.COMMON.button.borderColor};
  text-transform: uppercase;
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    width: ${(props) => props?.name?.includes('newPos') ? '80%' : '40%'}; 
  }
  
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.COMMON.button.color};
  }
  
  &:disabled {
    background-color: ${(props) => props.theme.colors.COMMON.button.disabled.backgroundColor};
    color: ${(props) => props.theme.colors.COMMON.button.disabled.color};
    border-color: ${(props) => props.theme.colors.COMMON.button.disabled.borderColor};
  }

  ${theme('brand', {
    AMQ: css`
    font-size: 11pt;
    `,
    BAL: css`
      left: 0;
      width: 100%;
      height: 53px;
      font-size: 12pt;
      border-right: none;
      border-left: none;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 100%;
      }
    `,
    BR: css`
      font-size: 11pt;
      letter-spacing: 2.6px;
    `,
    BV: css`
      height: 36px;
      font-size: 11pt;
    `,
    GUCCI: css`
      font-size: 11pt;
    `,
    YSL: css`
      left: 0;
      width: 100%;
      height: 53px;
      font-size: 10pt;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.button.borderBottomColor};
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.button.borderTopColor};
      background-color: transparent;
      font-weight: bold;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 100%;
      }
    `,
  })};
`;

export const FormButton = styled(Button)`
  ${theme('brand', {
    BAL: css`
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderBottomColor};
    `,
    BR: css`
      // because the privacy labels contain a strong tag, we need to cancel the bold from the strong tags
      div > strong {
        font-weight: normal;
      }
    `,
    YSL: css`
      height: 53px;
      color: ${(props) => props.theme.colors.COMMON.button.form.color};
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderBottomColor};
      
      &:disabled {
        color: ${(props) => props.theme.colors.COMMON.button.form.disabled.color};
        background-color: ${(props) => props.theme.colors.COMMON.button.form.disabled.backgroundColor};
        border-right: none;
        border-left: none;
  }
    `,
  })};
`;

export const CancelButton = styled(FormButton)`
    color: ${(props) => props.theme.colors.COMMON.button.backgroundColor};
    background-color: ${(props) => props.theme.colors.COMMON.button.color};
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%
`;

export const FormButtonPIN = styled(FormButton)`
  margin-bottom: 85px; //To be above the reCaptcha badge
`;

export const RemoveItemButton = styled.button`
  border: unset;
  color: ${(props) => props.theme.colors.COMMON.button.color};
  background-color: ${(props) => props.theme.colors.COMMON.button.backgroundColor};
  border-radius: 100px;
  margin: 0 10px;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  font-weight: bold;
  cursor: pointer;
`;

export const ActionButton = styled(Button)`
  margin: unset;
  width: unset !important;
  padding-inline: 30px;
`;

export const RadioButton = styled.input<{height?: number}>`
    height: ${(props) => `${props.height}px`};
    margin: 5px;
    accent-color: #232323;
`;
