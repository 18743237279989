import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '@common/components/form/Button';

type SocialLoginButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  logo: React.ReactNode,
  label: string,
  id?: string
}

const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({ logo, label, id, onClick }) => (
  <StyledSocialLoginButton onClick={onClick} id={id}>
    <span>{logo}</span>
    <span>{label}</span>
  </StyledSocialLoginButton>
);

const StyledSocialLoginButton = styled(Button)`
  width: 100%;
  display: flex;
  height: 44px;
  column-gap: 25px;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.COMMON.button.backgroundColor};
  background-color: ${(props) => props.theme.colors.COMMON.button.color};
  border: 1px solid black;
`;

export default SocialLoginButton;
