import React from 'react';
import { useController, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { ConnectForm, Label, Select } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ListOfValuesItemInterface, ValuesListFieldComponent } from '@/types/api';
import { ValuesListFieldComponentEnum } from '@/types/enum/FieldComponent.enum';

type Props = {
  name: string,
  defaultValue?: string
}

export const Type: React.FC<Props> = ({ name, defaultValue }) => (
  <ConnectForm>
    {({ register, t, control, fieldsConfiguration }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { field } = useController({ name, control, defaultValue });
      const addressTypeFieldInfos = Utils.getFieldComponent<ValuesListFieldComponent>(fieldsConfiguration, ValuesListFieldComponentEnum.ADDRESS_TYPE);

      return (
        <>
          <Label htmlFor={name}>{t('web.addressTypeLabel')}</Label>
          <Select
            id={name}
            defaultValue={field.value}
            name={name}
            parentRef={register()}
            disabled={!addressTypeFieldInfos?.isEditable}
          >
            {(addressTypeFieldInfos?.listOfValues as ListOfValuesItemInterface[]).map((type) => (
              <option
                value={type.value}
                key={type.value}
              >
                {type.label}
              </option>
            ))}
          </Select>
        </>
      );
    }}
  </ConnectForm>
);
