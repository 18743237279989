import Utils from '@utils/utils';
import React from 'react';
import { NamesWrapper } from '@pages/Identity/identity.styles';
import { LastName } from '@common/components/form/informationWithWireApi/LastName';
import { FirstName } from '@common/components/form/informationWithWireApi/FirstName';
import { FuriganaLastName } from '@common/components/form/informationWithWireApi/FuriganaLastName';
import { FuriganaFirstName } from '@common/components/form/informationWithWireApi/FuriganaFirstName';
import { MiddleName } from '@common/components/form/informationWithWireApi/MiddleName';
import { useStateMachine } from 'little-state-machine';
import { TextFieldComponent } from '@/types/api';
import { TextFieldComponentEnum } from '@/types/enum/FieldComponent.enum';

export const Names: React.FC = () => {
  const { state } = useStateMachine();
  const { customer, translations, prospectSource, fieldsConfiguration } = state;
  const { lightLanguageCode } = translations;
  const { customerContact: { address: { country } } } = customer;
  const firstName = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.FIRST_NAME);
  const lastName = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.LAST_NAME);
  const middleName = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.MIDDLE_NAME);
  const furiganaFirstName = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.FURIGANA_FIRST_NAME);
  const furiganaLastname = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.FURIGANA_LAST_NAME);

  return Utils.isFirstNameAndLastNameReversed(lightLanguageCode, country)
    ? (
      <>
        <NamesWrapper $prospectSource={prospectSource}>
          {lastName?.isAuthorized && <LastName prospectSource={prospectSource} lastName={lastName} />}
          {middleName?.isAuthorized && <MiddleName prospectSource={prospectSource} middleName={middleName} />}
          {firstName?.isAuthorized && <FirstName prospectSource={prospectSource} firstName={firstName} />}
        </NamesWrapper>
        {Utils.isFuriganaDisplayed(country) && (
          <NamesWrapper $prospectSource={prospectSource}>
            {furiganaLastname?.isAuthorized && <FuriganaLastName furiganaLastName={furiganaLastname} />}
            {furiganaFirstName?.isAuthorized && <FuriganaFirstName furiganaFirstName={furiganaFirstName} />}
          </NamesWrapper>
        )}
      </>
    ) : (
      <>
        <NamesWrapper $prospectSource={prospectSource}>
          {firstName?.isAuthorized && <FirstName prospectSource={prospectSource} firstName={firstName} />}
          {middleName?.isAuthorized && <MiddleName prospectSource={prospectSource} middleName={middleName} />}
          {lastName?.isAuthorized && <LastName prospectSource={prospectSource} lastName={lastName} />}
        </NamesWrapper>
        {Utils.isFuriganaDisplayed(country) && (
          <NamesWrapper $prospectSource={prospectSource}>
            {furiganaLastname?.isAuthorized && <FuriganaFirstName furiganaFirstName={furiganaLastname} />}
            {furiganaFirstName?.isAuthorized && <FuriganaLastName furiganaLastName={furiganaFirstName} />}
          </NamesWrapper>
        )}
      </>
    );
};
