import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { CountrySearchResults } from '@pages/CountryPage/components/CountrySearchResults';
import mp from '@services/mixpanel/mixpanel.service';
import { Country, IndexedCountries } from '@/types';
import { SearchBar } from '@/common/components';
import { PageContainer } from '@/common/components/containers';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import useTranslatedCountries from '@/services/hooks/useTranslatedCountries';
import { ImgFormLogo, LogoFormWrapper } from '@/common/components/Logo';
import { Widget } from '@/common/components/SearchBar';
import { PageID } from '@/types/enum/pageID';
import { ThemeType } from '@/types/enum/themeType';
import { FullPageLoader } from '@/common/components/Loader';

const CountryPage: React.FC = () => {
  useScrollToTop();
  useLastPageVisited(PageID.COUNTRY);

  // Prepare translations
  const t = useTranslation();
  const translatedCountries = useTranslatedCountries();

  // Retrieve current state from store
  const { state } = useStateMachine();
  const { brand, countries, store, customer, flow, translations, isLoading, featuresActivated, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const defaultCountryCode = customer.customerContact.address.country ? customer.customerContact.address.country : store.countryCode;
  let defaultCountry;
  if (!featuresActivated.IS_CERTIFICATE_OF_CRAFT_FLOW_ACTIVATED) {
    // if the PrefilledClientData country is not the countryCode
    defaultCountry = defaultCountryCode.length > 2 ? countries.find((country: Country) => country.nameCDB === defaultCountryCode) : countries.find((country: Country) => country.code === defaultCountryCode);
  }
  useThemeColor(brand, ThemeType.FORM);

  // current page
  const localStep = PageID.COUNTRY;

  // Retrieve token from the URL
  const token = useParams<{ token: string }>().token ?? '';

  // Prepare navigation
  const navigate = useNavigate();

  const [indexedCountries, setIndexedCountries] = useState<IndexedCountries>({});
  const [search, setSearch] = useState('');
  const [isWriting, setIsWriting] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [socialLoginModalDisplayed, setSocialLoginModalDisplayed] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // create indexed countries list
    const newIndexedCountries: IndexedCountries = {};
    translatedCountries.forEach((country: Country) => {
      if (newIndexedCountries[country.name.charAt(0)]) {
        newIndexedCountries[country.name.charAt(0)].push(country);
      } else {
        newIndexedCountries[country.name.charAt(0)] = [country];
      }
    });
    setIndexedCountries(newIndexedCountries);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode }}>
        {isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              <LogoFormWrapper><ImgFormLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></LogoFormWrapper>
              <>
                <SearchBar
                  windowWidth={windowWidth}
                  placeholder={t('dcc.searchCountry')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(Utils.normalize(e.target.value))}
                  onFocus={() => setIsWriting(true)}
                  onBlur={() => setIsWriting(false)}
                  value={search}
                  onCrossClick={() => setSearch('')}
                  widget={Widget.CROSS}
                  id="searchBar"
                  name="searchBar"
                />
                { /* search country based on user input and country names */ }
                <CountrySearchResults
                  isWriting={isWriting}
                  defaultCountry={defaultCountry}
                  indexedCountries={indexedCountries}
                  search={search}
                  localStep={localStep}
                  token={token}
                  socialLoginModalDisplayed={socialLoginModalDisplayed}
                  setSocialLoginModalDisplayed={setSocialLoginModalDisplayed}
                />
              </>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default CountryPage;
