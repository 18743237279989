import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { BirthDateWrapper, DayMonthWrapper, StyleSlashDate } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { Month } from '@common/components/form/informationWithWireApi/Month';
import { Day } from '@common/components/form/informationWithWireApi/Day';
import { Year } from '@common/components/form/informationWithWireApi/Year';
import { Label } from '@common/components/form';
import { useTranslation } from '@services/hooks';
import { useStateMachine } from 'little-state-machine';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { DateFieldComponent } from '@/types/api';

type Props = {
  formMethods: UseFormMethods<InformationFormProps>,
  setShowDateError: Dispatch<SetStateAction<boolean>>,
  showDateError: boolean,
  birthDate: DateFieldComponent
}

export const BirthDate: React.FC<Props> = ({ formMethods, setShowDateError, showDateError, birthDate }) => {
  const { state } = useStateMachine();
  const { customer: { customerContact: { address: { country } } } } = state;
  const t = useTranslation();
  useEffect(() => {
    (async function fetch() {
      // It prevents the submit button to be disabled if user goes back from the next page or if he refreshes the page.
      await formMethods.trigger();
    }());
  }, []);

  return (
    <>
      <Label htmlFor="dayBirth">{t('web.dateOfBirth')}</Label>
      <BirthDateWrapper>
        <DayMonthWrapper>
          {Utils.isMonthAndDayReversed(country)
            ? (
              <>
                <Month
                  birthDate={birthDate}
                  formMethods={formMethods}
                  setShowDateError={setShowDateError}
                  showDateError={showDateError}
                />
                <StyleSlashDate>/</StyleSlashDate>
                <Day
                  birthDate={birthDate}
                  formMethods={formMethods}
                  setShowDateError={setShowDateError}
                  showDateError={showDateError}
                />
              </>
            ) : (
              <>
                <Day
                  birthDate={birthDate}
                  formMethods={formMethods}
                  setShowDateError={setShowDateError}
                  showDateError={showDateError}
                />
                <StyleSlashDate>/</StyleSlashDate>
                <Month
                  birthDate={birthDate}
                  formMethods={formMethods}
                  setShowDateError={setShowDateError}
                  showDateError={showDateError}
                />
              </>
            )}
          <StyleSlashDate>/</StyleSlashDate>
        </DayMonthWrapper>
        <Year
          birthDate={birthDate}
          formMethods={formMethods}
          setShowDateError={setShowDateError}
          showDateError={showDateError}
        />
      </BirthDateWrapper>
    </>
  );
};
