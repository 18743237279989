import Utils from '@utils/utils';
import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { ProspectSource } from '@/types';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

type Props = {
  prospectSource: ProspectSource
  lastName: TextFieldComponent
}

export const LastName: React.FC<Props> = ({ prospectSource, lastName }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="lastName">{t('dcc.lastname')}</Label>
        <Field
          name="lastName"
          id="lastName"
          ref={register({
            required: lastName.isMandatory,
            pattern: Utils.stringToRegex(lastName.regex),
            validate: (value) => Utils.doesNotContainOnlySpaces(value)
          })}
          defaultValue={customer.customerInfo.lastname}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'family-name'}
          disabled={!lastName.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
