import regex from '@utils/regex';
import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { UseFormMethods } from 'react-hook-form';
import ContactService from '@pages/Contact/contact.service';
import { useStateMachine } from 'little-state-machine';
import WarningMessage from '@common/components/form/WarningMessage';
import { FormWarningContext } from '@common/components/form/FormWarningContext';
import { ConnectForm } from '@/common/components/form';
import Label from '@/common/components/form/Label';
import { PreferredMethodOfContact, ProspectSource } from '@/types';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { PageID } from '@/types/enum/pageID';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

type Props = {
  isPrimaryEmail?: boolean,
  fieldName?: string,
  isNewPosEmail?: boolean,
  preferredMethodOfContact?: PreferredMethodOfContact,
  showEmailError: boolean,
  setShowEmailError: Dispatch<SetStateAction<boolean>>,
  emailUserInputValue: string,
  setEmailUserInputValue?: Dispatch<SetStateAction<string>>,
  prospectSource: ProspectSource,
  emailError: boolean,
  formMethods: UseFormMethods<InformationFormProps>,
  setPhoneError?: Dispatch<SetStateAction<boolean>>,
  setEmailError: Dispatch<SetStateAction<boolean>>,
  localStep: PageID,
  emailField?: TextFieldComponent,
  shouldBeDisabled?: boolean,
}

export const Email: React.FC<Props> = ({
  isPrimaryEmail,
  fieldName,
  isNewPosEmail,
  preferredMethodOfContact,
  showEmailError,
  setShowEmailError,
  emailUserInputValue,
  setEmailUserInputValue,
  emailError,
  prospectSource,
  formMethods,
  setPhoneError,
  setEmailError,
  localStep,
  emailField,
  shouldBeDisabled,
}) => {
  const { state } = useStateMachine();
  const { featuresActivated } = state;
  const isFieldDisabled = shouldBeDisabled || (localStep === PageID.INFORMATION && featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && preferredMethodOfContact === PreferredMethodOfContact.EMAIL);
  const { riskyEmailAlert, cancelProceedAttempt } = useContext(FormWarningContext);

  return (
    <ConnectForm>
      {({ register, t, getValues, errors: { invalidEmailAlert } }: UseFormMethods<InformationFormProps> & TranslationFunction) => (
        <>
          {!isNewPosEmail && <Label htmlFor="email">{t('dcc.email')}</Label>}
          {preferredMethodOfContact === PreferredMethodOfContact.PHONE
          && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
          <Field
            name={fieldName || 'email'}
            id={fieldName || 'email'}
            type="email"
            ref={register({
              required: emailField?.isMandatory || isPrimaryEmail || (preferredMethodOfContact && preferredMethodOfContact !== PreferredMethodOfContact.PHONE),
              pattern: (emailField?.regex && Utils.stringToRegex(emailField.regex)) || regex.email,
            })}
            defaultValue={emailUserInputValue}
            hasError={(emailError || invalidEmailAlert) && showEmailError}
            hasWarning={Boolean(riskyEmailAlert) && showEmailError}
            autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'email'}
            onBlur={() => {
              ContactService.handleEmailBlur(setShowEmailError);
              if (setPhoneError && preferredMethodOfContact) ContactService.checkInputFormat(formMethods, setPhoneError, setEmailError, preferredMethodOfContact);
            }}
            onFocus={() => ContactService.handleEmailFocus(setShowEmailError, formMethods, cancelProceedAttempt)}
            maxLength={100}
            onKeyDown={(event) => ContactService.blurField(event)}
            onChange={setEmailUserInputValue ? (() => setEmailUserInputValue(getValues().email)) : undefined}
            disabled={emailField?.isEditable === false || isFieldDisabled}
          />
          {emailError && showEmailError && <ErrorMessage>{t('dcc.invalidEmail')}</ErrorMessage>}
          {invalidEmailAlert && showEmailError && <ErrorMessage id="invalidEmailAlert">{invalidEmailAlert.message}</ErrorMessage>}
          {riskyEmailAlert && showEmailError && <WarningMessage id="riskyEmailAlert">{riskyEmailAlert}</WarningMessage>}
          {}
        </>
      )}
    </ConnectForm>
  );
};
