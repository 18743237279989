import React from 'react';
import { useController, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field, OptionalLegend } from '@common/components/form/Field';
import { ConnectForm, ErrorMessage, Label } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponentEnum } from '@/types/enum/FieldComponent.enum';

type Props = {
  name: string,
  index: number,
  defaultValue?: string
}

export const City: React.FC<Props> = ({ name, index, defaultValue }) => (
  <ConnectForm>
    {({ errors, t, control, fieldsConfiguration }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const cityFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.CITY);
      const { field, meta } = useController({ name,
        rules: {
          required: cityFieldInfos?.isMandatory,
          pattern: Utils.stringToRegex(cityFieldInfos?.regex),
          validate: (value) => Utils.doesNotContainOnlySpaces(value)
        },
        control,
        defaultValue: defaultValue || '' });

      const cityError = errors.addresses?.[index]?.city;

      return (
        <>
          <Label htmlFor={name}>{t('web.city')}</Label>
          {!cityFieldInfos?.isMandatory && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
          <Field
            id={name}
            {...field}
            autoComplete="address-level2"
            disabled={!cityFieldInfos?.isEditable}
            hasError={Boolean(errors.addresses?.[index]?.city && meta.isTouched)}
          />
          {cityError && (<ErrorMessage id="cityError">{t('web.invalidFormat')}</ErrorMessage>)}
        </>
      );
    }}
  </ConnectForm>
);
