import React from 'react';
import { useTranslation } from '@services/hooks';
import ButtonBottomWrapper from '@common/components/containers/ButtonBottomWrapper';
import { PageID } from '@/types/enum/pageID';
import { CancelButton } from '@/common/components/form/Button';
import { ICancelButtonContainerProps } from '@/types/form/buttonContainerProps';

const CancelButtonContainer = (props: ICancelButtonContainerProps) => {
  const t = useTranslation();
  const { isSubmitting, onClick, pageId } = props;

  return (
    <ButtonBottomWrapper isNotUniqueButton={pageId === PageID.UPDATE_NEW_POS_CUSTOMER}>
      <CancelButton
        id="cancelButtonDefault"
        name={pageId === PageID.UPDATE_NEW_POS_CUSTOMER ? 'newPosCancelButton' : 'cancelButton'}
        type="reset"
        disabled={isSubmitting}
        onClick={onClick}
      >
        {t('web.newpos.reset')}
      </CancelButton>
    </ButtonBottomWrapper>
  );
};

export default CancelButtonContainer;
