import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field, OptionalLegend } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

interface Props {
  customerType: TextFieldComponent;
}

export const CustomerType: React.FC<Props> = ({ customerType }) => (
  <ConnectForm>
    {({ register, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="customerType">{t('web.customerType')}</Label>
        {!customerType?.isMandatory && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
        <Field
          id="customerType"
          name="customerType"
          ref={register({
            required: customerType.isMandatory,
            pattern: Utils.stringToRegex(customerType?.regex),
            validate: (value) => Utils.doesNotContainOnlySpaces(value)
          })}
          disabled={!customerType.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
