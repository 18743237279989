import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import useTranslation from '@services/hooks/useTranslation';
import { Label, Select } from '@common/components/form';
import Utils from '@utils/utils';
import { useParams } from 'react-router-dom';
import { InformationFormProps } from '@/types/form/informationFormProps';
import {
  FieldComponentEnum,
  ValuesListFieldComponentEnum
} from '@/types/enum/FieldComponent.enum';
import { ListOfValuesItemInterface, ValuesListFieldComponent } from '@/types/api';
import api from '@/api';
import { updateFieldsConfiguration
} from '@/littleStateMachine/actions';
import { Country } from '@/types';

type Props = {
  name: string,
  index: number
}

export const State: React.FC<Props> = ({ name, index }) => {
  const { state, actions } = useStateMachine({ updateFieldsConfiguration });
  const { customer, countries, translations } = state;
  const { customerContact: { address: { country: primaryCustomerCountry } } } = customer;
  const t = useTranslation();

  const { control, register } = useFormContext<InformationFormProps>();
  const { token } = useParams<{ token: string }>();

  const countryField = useWatch({ name: `addresses.${index}.country`, control });
  const customerCountry = countries.find((country) => country.nameCDB === countryField);

  const formerCustomerCountry = useRef<Country>();
  const [stateFieldInfos, setStateFieldInfos] = useState<ValuesListFieldComponent>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchStateListOfValue = async (country: Country) => {
    if (country.hasStates && country.states.length > 0) {
      setIsLoading(true);
      const stateFieldName = `${country?.nameCDB}_STATES` as unknown as FieldComponentEnum;
      const stateFieldNameArray = [stateFieldName as ValuesListFieldComponentEnum];
      const { data: fieldsConfigurationResponse } = await api.getFields(translations.lightLanguageCode, primaryCustomerCountry, token, stateFieldNameArray);
      if (!Utils.checkFieldComponent(fieldsConfigurationResponse.fieldsResponse.meta.names, stateFieldNameArray)) {
        throw new TypeError('Missing fields received from wire api');
      }
      actions.updateFieldsConfiguration(fieldsConfigurationResponse.fieldsResponse.data.fieldComponents);
      setStateFieldInfos(Utils.getFieldComponent<ValuesListFieldComponent>(fieldsConfigurationResponse.fieldsResponse.data.fieldComponents, stateFieldName));
      setIsLoading(false);
    } else {
      setStateFieldInfos(undefined);
    }
  };

  useEffect(() => {
    if (customerCountry !== formerCustomerCountry.current) {
      formerCustomerCountry.current = customerCountry;
      if (customerCountry) {
        fetchStateListOfValue(customerCountry);
      }
    }
  }, [customerCountry]);

  if (isLoading) return <p>Loading...</p>;

  if (stateFieldInfos?.isAuthorized) {
    return (
      <>
        <Label htmlFor={name}>{t('dcc.state')}</Label>
        <Select
          id={name}
          name={name}
          parentRef={register({ required: stateFieldInfos?.isMandatory })}
          disabled={!stateFieldInfos?.isEditable}
        >
          {(stateFieldInfos?.listOfValues as ListOfValuesItemInterface[]).map((state) => (
            <option
              value={state.value}
              key={state.value}
            >
              {state.label}
            </option>
          ))}
        </Select>
      </>
    );
  } return (<></>);
};
