import React from 'react';
import { useController, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { ConnectForm } from '@common/components/form';
import { RadioButton } from '@common/components/form/Button';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  name: string,
  index: number,
  onChange: (index: number) => void,
  label?: string,
  height?: number,
  defaultValue?: boolean
}

export const Radio: React.FC<Props> = ({ name, index, onChange, label, height, defaultValue }) => (
  <ConnectForm>
    {({ t, control }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { field } = useController({ name, control, defaultValue: defaultValue || false });
      return (
        <>
          <RadioButton
            id={name}
            type="radio"
            checked={field.value || false}
            name={name}
            onChange={() => onChange(index)}
            height={height}
          />
          {label && <label htmlFor={name}>{t(label)}</label> }
        </>
      );
    }}
  </ConnectForm>
);
