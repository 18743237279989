import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field, OptionalLegend } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

interface Props {
  customerSinceDate: TextFieldComponent;
}

export const CustSinceDate: React.FC<Props> = ({ customerSinceDate
}) => (
  <ConnectForm>
    {({ register, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="custAnnivDate">{t('dcc.custAnnivDate')}</Label>
        {!customerSinceDate?.isMandatory && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
        <Field
          id="custAnnivDate"
          name="custAnnivDate"
          ref={register({
            required: customerSinceDate.isMandatory,
            pattern: Utils.stringToRegex(customerSinceDate?.regex),
            validate: (value) => Utils.doesNotContainOnlySpaces(value)
          })}
          disabled={!customerSinceDate.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
