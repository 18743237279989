import styled from 'styled-components';

export const SizedBox = styled.div<{ $height?: number; $width?: number }>`
    height: ${({ $height }) => `${$height}px`};
    width: ${({ $width }) => `${$width}px`};
`;

export const Row = styled.div<{ $alignItems?: string }>`
    display: flex;
    flex-direction: row;
    align-items: ${({ $alignItems }) => $alignItems || 'center'};
`;
