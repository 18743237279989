import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

type Props = {
  isNotUniqueButton?: boolean,
}

const ButtonBottomWrapper = styled.div< Props >`
  position: ${(props) => props?.isNotUniqueButton ? 'relative' : 'absolute'};
  width: 100%;
  bottom: 60px; // should be < than button height + form margin bottom to prevent overlaps from top component
  left: 0;
  display: flex;
  
  ${theme('brand', {
    YSL: css`
      height: 53px;
      bottom: -1px; // should be < than button height + form margin bottom to prevent overlaps from top component
    `,
    BAL: css`
      bottom: -1px; // should be < than button height + form margin bottom to prevent overlaps from top component
    `,
  })};
`;

export default ButtonBottomWrapper;

export const ButtonBottomWrapperPIN = styled(ButtonBottomWrapper)`

  ${theme('brand', {
    GUCCI: css`
      position: relative;
    `,
    BV: css`
      position: relative;
    `,
    BR: css`
      position: relative;
    `,
    AMQ: css`
      position: relative;
    `,
  })};
`;
