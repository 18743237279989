import React, { useEffect } from 'react';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { ActionButton } from '@common/components/form/Button';
import { State } from '@pages/UpdateNewPosCustomer/components/Address/State';
import { Street } from '@pages/UpdateNewPosCustomer/components/Address/Street';
import { City } from '@pages/UpdateNewPosCustomer/components/Address/City';
import { Zipcode } from '@pages/UpdateNewPosCustomer/components/Address/Zicode';
import { Row, SizedBox } from '@common/components/layout.style';
import { SectionTitle } from '@pages/Information/information.styles';
import { Type } from '@pages/UpdateNewPosCustomer/components/Address/Type';
import { Building } from '@pages/UpdateNewPosCustomer/components/Address/Building';
import { Block } from '@pages/UpdateNewPosCustomer/components/Address/Block';
import { FloorAptNb } from '@pages/UpdateNewPosCustomer/components/Address/FloorAptNb';
import Utils from '@utils/utils';
import { Field } from '@common/components/form/Field';
import { Radio } from '@pages/UpdateNewPosCustomer/components/Radio';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ConnectForm } from '@/common/components/form/index';
import { AddressField, InformationFormProps } from '@/types/form/informationFormProps';
import { Country } from './Address/Country';
import {
  TextFieldComponentEnum,
  ValuesListFieldComponentEnum
} from '@/types/enum/FieldComponent.enum';

export const Addresses: React.FC = () => (
  <ConnectForm>
    {({ control, t, register, setValue, fieldsConfiguration }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { fields, remove, append } = useFieldArray<AddressField>({ name: 'addresses', control: control! });
      const addressTypeFieldInfos = Utils.getFieldComponent(fieldsConfiguration, ValuesListFieldComponentEnum.ADDRESS_TYPE);
      const countryFieldInfos = Utils.getFieldComponent(fieldsConfiguration, ValuesListFieldComponentEnum.COUNTRY);
      const streetFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.STREET);
      const cityFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.CITY);
      const buildingFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.BUILDING);
      const blockFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.BLOCK);
      const floorAptNbFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.FLOOR_APT_NB);

      const removeAddress = (index: number) => {
        remove(index);
      };

      const addAddress = () => append({ type: '_EMPTY' });

      const handleCheckboxOnchange = ((index: number) => {
        fields.map((_, addressObjIndex) => setValue(`addresses.${addressObjIndex}.primary`, false));
        setValue(`addresses.${index}.primary`, true);
      });

      useEffect(() => {
        if (fields.every((field) => field.primary !== true)) {
          setValue('addresses.0.primary', true);
        }
      }, [fields]);

      return (
        <div>
          {fields.length > 0 && fields.map((field, index) => (
            <div key={field.id}>
              <Row $alignItems="center">
                <SectionTitle>{t('dcc.address')}</SectionTitle>
                <SizedBox $width={10} />
                <Radio name={`addresses.${index}.primary`} index={index} onChange={handleCheckboxOnchange} label="dcc.primary" defaultValue={field.primary} />
                {fields.length > 1 && (
                <>
                  <SizedBox $width={15} />
                  <ActionButton id={`removeAddress-${index}`} onClick={() => removeAddress(index)}>{t('web.remove')}</ActionButton>
                </>
                )}
              </Row>
              <SizedBox $height={15} />
              <Field isHidden ref={register()} name={`addresses.${index}.name`} defaultValue={field.name} />
              {addressTypeFieldInfos?.isAuthorized && <Type name={`addresses.${index}.type`} defaultValue={field.type} />}
              {countryFieldInfos?.isAuthorized && <Country name={`addresses.${index}.country`} defaultValue={field.country} />}
              <State name={`addresses.${index}.state`} index={index} />
              {streetFieldInfos?.isAuthorized && <Street name={`addresses.${index}.street`} index={index} defaultValue={field.street} />}
              {buildingFieldInfos?.isAuthorized && <Building name={`addresses.${index}.building`} index={index} defaultValue={field.building} />}
              {blockFieldInfos?.isAuthorized && <Block name={`addresses.${index}.block`} index={index} defaultValue={field.block} />}
              {floorAptNbFieldInfos?.isAuthorized && <FloorAptNb name={`addresses.${index}.floorAptNb`} index={index} defaultValue={field.floorAptNb} />}
              {cityFieldInfos?.isAuthorized && <City name={`addresses.${index}.city`} index={index} defaultValue={field.city} />}
              <Zipcode name={`addresses.${index}.zipCode`} index={index} defaultValue={field.zipCode} />
              <SizedBox $height={15} />
            </div>
          ))}

          {fields.length === 0 && (
            <span>{t('web.noAddressProvided')}</span>
          )}

          {/* type="button" prevent the page from reloading on click */}
          <ActionButton id="addAddress" type="button" onClick={() => addAddress()}>{t('web.addItem')}</ActionButton>
        </div>
      );
    }}
  </ConnectForm>
);
