import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { FullPageLoader } from '@common/components/Loader';
import FlowService from '@services/flow.service';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { NextButtonContainer, FormContainer } from '@common/components/containers';
import Utils from '@utils/utils';
import { SectionTitle } from '@pages/Information/information.styles';
import { useStateMachine } from 'little-state-machine';
import { useLastPageVisited, useScrollToTop, useTranslation } from '@services/hooks';
import { ProgressBar, Title } from '@common/components';
import { CompanyName } from '@pages/UpdateNewPosCustomer/components/CompanyName';
import { CivilTitle } from '@common/components/form/informationWithWireApi/CivilTitle';
import { Names } from '@common/components/form/informationWithWireApi/Names';
import { BirthDate } from '@common/components/form/informationWithWireApi/BirthDate';
import { CustomerType } from '@pages/UpdateNewPosCustomer/components/CustomerType';
import { CustSinceDate } from '@pages/UpdateNewPosCustomer/components/CustSinceDate';
import { Addresses } from '@pages/UpdateNewPosCustomer/components/Addresses';
import { SizedBox } from '@common/components/layout.style';
import { SaNote } from '@pages/UpdateNewPosCustomer/components/SaNote';
import { ErrorMessage } from '@common/components/form';
import { ImgFormLogo, LogoFormWrapper } from '@common/components/Logo';
import LanguagesDropdown from '@common/components/dropdown/LanguagesDropdown';
import CancelButtonContainer from '@common/components/containers/CancelButtonContainer';
import { ButtonContainer } from '@common/components/form/Button';
import { Emails } from '@pages/UpdateNewPosCustomer/components/Emails';
import { Phones } from '@pages/UpdateNewPosCustomer/components/Phones';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { PageID } from '@/types/enum/pageID';
import PageContainer from '@/common/components/containers/PageContainer';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateBrandCountryConfiguration,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateFieldsConfiguration,
  updatePrivacyConf
} from '@/littleStateMachine/actions';
import mp from '@/services/mixpanel/mixpanel.service';
import {
  DateFieldComponentEnum, FieldComponentEnum,
  FieldComponentUpdateNewPosCustomerPageEnum,
  GenderListFieldComponentEnum,
  LanguageListFieldComponentEnum,
  TextFieldComponentEnum
} from '@/types/enum/FieldComponent.enum';
import {
  DateFieldComponent,
  GenderListFieldComponent,
  LanguagesListFieldComponent,
  TextFieldComponent
} from '@/types/api';
import { TYPE_DROPDOWN } from '@/types';
import { Email } from '@/types/email';

const UpdateNewPosCustomer: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.UPDATE_NEW_POS_CUSTOMER);
  const localStep = PageID.UPDATE_NEW_POS_CUSTOMER;
  const navigate = useNavigate();
  const t = useTranslation();

  const { state, actions } = useStateMachine({ updateAlreadyRegistered, updateAlreadyExistingCustomer, updateErrorTypeOnError, updateBrandCountryConfiguration, updatePrivacyConf, updateCustomerInformation, updateFieldsConfiguration });
  const { brand, prospectSource, flow, mixPanelToken, actualToken, fieldsConfiguration, customer } = state;
  const { customerInfo, customerContact, saComment, registrationDateInStore } = customer;
  const { title, firstname, lastname, middleName, day, month, year, companyName, customerType } = customerInfo;
  const { addresses, emails, email, emailType, phones } = customerContact;

  const currentStep = FlowService.getCurrentStepNumber(state, localStep);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useParams<{ token: string }>();
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;

  // Wrong usage. Component to be migrated.
  const [showDateError, setShowDateError] = useState(false);

  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { formState: { isSubmitting, isValid }, reset } = formMethods;

  useEffect(() => {
    if (!isLoading) {
      // We use reset to set defaultValue asynchronously
      const allEmails = emails.map((otherEmail) => ({
        ...otherEmail,
        primary: false
      }));
      allEmails.unshift({
        address: email,
        type: emailType,
        primary: true
      });

      reset({
        title,
        firstName: firstname,
        middleName,
        lastName: lastname,
        day: parseInt(day, 10),
        month: parseInt(month, 10),
        year: parseInt(year, 10),
        customerType,
        companyName,
        saComment,
        custAnnivDate: registrationDateInStore,
        addresses,
        phones,
        emails: allEmails
      });
    }
  }, [isLoading]);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        try {
          setIsLoading(true);
          const { data } = await api.getPartialUpdateInformation(token);
          actions.updateAlreadyRegistered(true);
          // Move the primary address up the list
          data.customerContact.addresses.sort((item) => item.primary ? -1 : 1);
          actions.updateAlreadyExistingCustomer(data);
          const customerAddress = data.customerContact.address;
          const { data: payload } = await api.fetchBrandCountryConf(token, customerAddress.country, customerAddress.state);
          actions.updateBrandCountryConfiguration(payload);
          const { data: fieldsConfigurationResponse } = await api.getFields(state.translations.lightLanguageCode, customerAddress.country, token, Object.values(FieldComponentUpdateNewPosCustomerPageEnum) as FieldComponentEnum[]);
          if (!Utils.checkFieldComponent(fieldsConfigurationResponse.fieldsResponse.meta.names, Object.values(FieldComponentUpdateNewPosCustomerPageEnum) as string[])) {
            throw new TypeError('Missing fields received from wire api');
          }
          actions.updateFieldsConfiguration(fieldsConfigurationResponse.fieldsResponse.data.fieldComponents);
          setIsLoading(false);
        } catch (err) {
          if (err?.response?.data) {
            actions.updateErrorTypeOnError(err.response.data);
          }
          navigate('/error');
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token]);

  const onSubmit = formMethods.handleSubmit(async (formData) => {
    const { title, firstName, lastName, middleName, furiganaFirstName, furiganaLastName, day, month, year, addresses, companyName, emails, phones } = formData;
    const { address: primaryMailAddress, type: primaryMailType } = emails.find((emailToCheck) => emailToCheck.primary) as Email;
    const informationInfos = {
      title,
      firstName,
      lastName,
      middleName,
      furiganaFirstName,
      furiganaLastName,
      day: String(day),
      month: String(month),
      year: String(year),
      addresses,
      phones,
      companyName,
      email: primaryMailAddress,
      emailType: primaryMailType,
      emails: emails?.filter((otherEmail) => !otherEmail.primary)?.map((otherEmail) => ({
        address: otherEmail.address,
        type: otherEmail.type
      }))
    };
    actions.updateCustomerInformation(informationInfos);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  const onCancel = () => {
    window.location.reload();
  };

  const languages = Utils.getFieldComponent<LanguagesListFieldComponent>(fieldsConfiguration, LanguageListFieldComponentEnum.LANGUAGE);
  const gender = Utils.getFieldComponent<GenderListFieldComponent>(fieldsConfiguration, GenderListFieldComponentEnum.GENDER);
  const customerSinceDate = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.CUST_SINCE_DATE);
  const company = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.COMPANY);
  const customerTypeFieldInfos = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.CUST_TYPE);
  const birthDate = Utils.getFieldComponent<DateFieldComponent>(fieldsConfiguration, DateFieldComponentEnum.BIRTH_DATE);
  const emailField = Utils.getFieldComponent<TextFieldComponent>(fieldsConfiguration, TextFieldComponentEnum.EMAIL);
  if (brand && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, prospectSource }}>
        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              <ProgressBar numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} />
              {languages?.isAuthorized && <LanguagesDropdown languages={languages.listOfValues} type={TYPE_DROPDOWN.DEFAULT} /> }
              <LogoFormWrapper><ImgFormLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></LogoFormWrapper>
              <Title>{t('aboutClient.edit')}</Title>
              <FormProvider {...formMethods}>
                <form>
                  <FormContainer onKeyDown={(keyDownEvent) => Utils.validateForm(keyDownEvent, isValid, onSubmit)}>
                    <SectionTitle>{t('web.personalInformation')}</SectionTitle>
                    {gender?.isAuthorized && <CivilTitle gender={gender} /> }
                    <Names />
                    {birthDate?.isAuthorized && (
                    <BirthDate
                      formMethods={formMethods}
                      setShowDateError={setShowDateError}
                      showDateError={showDateError}
                      birthDate={birthDate}
                    />
                    )}
                    {showDateError && formMethods.errors.day?.type === 'dateFormatError' && <ErrorMessage>{t('dcc.dateOfBirthError')}</ErrorMessage>}
                    {showDateError && formMethods.errors.day?.type === 'minimalAgeError' && <ErrorMessage>{t('web.minimalAge')}</ErrorMessage>}
                    {customerSinceDate?.isAuthorized && <CustSinceDate customerSinceDate={customerSinceDate} />}
                    {company?.isAuthorized && <CompanyName company={company} />}
                    {customerTypeFieldInfos?.isAuthorized && <CustomerType customerType={customerTypeFieldInfos} />}

                    <SizedBox $height={40} />
                    <SectionTitle>{t('web.addresses')}</SectionTitle>
                    <Addresses />
                    <SizedBox $height={50} />
                    <SectionTitle>{t('dcc.phone')}</SectionTitle>
                    <Phones />
                    <SizedBox $height={50} />
                    {emailField?.isAuthorized && <Emails emailField={emailField} formMethods={formMethods} localStep={localStep} prospectSource={prospectSource} />}
                    <SizedBox $height={50} />
                    <SectionTitle>{t('web.comment')}</SectionTitle>
                    <SaNote />
                  </FormContainer>
                  <ButtonContainer>
                    <CancelButtonContainer isSubmitting={isSubmitting} pageId={PageID.UPDATE_NEW_POS_CUSTOMER} onClick={onCancel} />
                    <NextButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.UPDATE_NEW_POS_CUSTOMER} onSubmit={onSubmit} />
                  </ButtonContainer>
                </form>
              </FormProvider>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default UpdateNewPosCustomer;
