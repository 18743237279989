import React, { useEffect, useState } from 'react';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@services/hooks';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import FlowService from '@services/flow.service';
import { FormProvider, useForm } from 'react-hook-form';
import { ThemeProvider } from 'styled-components/macro';
import { NextButtonContainer, FormContainer, PageContainer } from '@common/components/containers';
import FormHeader from '@common/components/form/FormHeader';
import { Title } from '@common/components';
import Utils from '@utils/utils';
import { NamesWrapper } from '@pages/Identity/identity.styles';
import { LastName } from '@common/components/form/information/LastName';
import { FirstName } from '@common/components/form/information/FirstName';
import moment from 'moment/moment';
import { TYPE_DROPDOWN } from '@/types';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import { PageID } from '@/types/enum/pageID';
import { CheckCustomerData, CustomerNameFormProps } from '@/types/form/customer-name-form.props';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateCustomerInformation,
  updateErrorTypeOnError
} from '@/littleStateMachine/actions';
import { CustomerCheckResponseEnum } from '@/types/api';
import mp from '@/services/mixpanel/mixpanel.service';
import ErrorMessage from '@/common/components/form/ErrorMessage';

const CustomerName: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.CUSTOMER_NAME);

  // Prepare navigation
  const navigate = useNavigate();

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateAlreadyRegistered, updateAlreadyExistingCustomer, updateErrorTypeOnError, updateCustomerInformation });
  const { brand, prospectSource, actualToken, languages, translations, customer, featuresActivated, flow, mixPanelToken } = state;
  const { customerContact } = customer;
  const { email, phone: { prefix, number }, socialAccounts } = customerContact;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const firstNameLocal = customer.customerInfo.firstname;
  const lastNameLocal = customer.customerInfo.lastname;
  useThemeColor(brand, ThemeType.FORM);

  // local states
  const [firstNameUserInputValue, setFirstNameUserInputValue] = useState(firstNameLocal || '');
  const [lastNameUserInputValue, setLastNameUserInputValue] = useState(lastNameLocal || '');
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);

  // current page
  const localStep = PageID.CUSTOMER_NAME;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        // It prevents the submit button to be disabled if user goes back from the next page or if he refreshes the page.
        await formMethods.trigger();
        navigate(`/${actualToken}/${localStep.toLowerCase()}`);
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, actualToken]);

  const onSubmit = formMethods.handleSubmit(async ({ firstName, lastName }: CustomerNameFormProps) => {
    const customerData: CheckCustomerData = {
      firstName,
      lastName,
      phone: { prefix, number },
      email
    };
    actions.updateCustomerInformation({ firstName, lastName });
    try {
      const { data: { checkByNameAndContactResult, oktaLogin } } = await api.checkCustomerByNameAndContact(customerData, actualToken);

      // Avoid to trigger error in case of exhausted customer call
      if (checkByNameAndContactResult !== CustomerCheckResponseEnum.DUPLICATION_ALERT) {
        // Update mix-panel super properties
        await mp.updateCustomerFlowGlobally(actualToken);
      }

      if (checkByNameAndContactResult === CustomerCheckResponseEnum.UPDATE_AUTHORIZED) {
        actions.updateAlreadyRegistered(true);
        if (!oktaLogin) {
          const oktaCustomerData = {
            ...state.customer,
            customerInfo: {
              ...state.customer.customerInfo,
              firstname: firstName,
              lastname: lastName
            },
            language: translations.privacyLanguageCode, // push the privacy language even if customer.language is another language (application language)
            registrationDateInStore: moment().utc().format(),
            registrationSACode: state.salesAdvisor?.vendorId
          };
          const { data: oktaResponse } = await api.createOktaCustomer(oktaCustomerData, actualToken);
          // store okta id to send it in validateOtp request
          actions.updateCustomerInformation({ okta: oktaResponse.oktaLogin });
          if (featuresActivated.IS_GUCCI_DIGITAL_ACCOUNT_USED) {
            const customerDataSocial = {
              ...state.customer,
              customerContact: {
                ...customerContact,
                socialAccounts: {
                  ...socialAccounts,
                  oktaLogin: oktaResponse.oktaLogin
                }
              }
            };
            await api.updateSocial(customerDataSocial, token);
          }
        } else {
          actions.updateCustomerInformation({ okta: oktaLogin });
        }
        const nextPage = FlowService.nextPage(state, localStep);
        navigate(`/${actualToken}/${nextPage}`);
      } else if (checkByNameAndContactResult !== CustomerCheckResponseEnum.DUPLICATION_ALERT) {
        actions.updateAlreadyRegistered(false);
        const nextPage = FlowService.nextPage(state, localStep, PageID.INFORMATION);
        flow.authorizedPages = flow.authorizedPages.filter((page) => page !== PageID.VERIFICATION);
        navigate(`/${actualToken}/${nextPage}`);
      } else {
        // duplication alert case
        formMethods.setError('duplicationAlert', { type: '', message: Utils.isLuceProspectSource(prospectSource) ? t('web.duplicateClient') : t('dcc.duplicate') });
        mp.existingClient();
      }
    } catch (err) {
      actions.updateErrorTypeOnError(err.response?.data);
      navigate('/error');
    }
  });

  if (brand.code && featuresActivated.IS_WIRE_EDIT_PHASE_2_ACTIVATED) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code }}>
        <PageContainer>
          <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
          <Title>{Utils.isLuceProspectSource(prospectSource) ? t('web.aboutClient') : t('web.aboutYourself')}</Title>
          <FormProvider {...formMethods}>
            <form>
              <FormContainer onKeyDown={(event) => Utils.validateForm(event, isValid, onSubmit)}>
                {
                    Utils.isFirstNameAndLastNameReversed(translations.lightLanguageCode, customer.customerContact.address.country)
                      ? (
                        <NamesWrapper>
                          <LastName prospectSource={prospectSource} lastNameUserInputValue={lastNameUserInputValue} setLastNameUserInputValue={setLastNameUserInputValue} setShowLastNameError={setShowLastNameError} formMethods={formMethods} isDisabled={false} isDuplicationTested={featuresActivated.IS_GUCCI_DIGITAL_ACCOUNT_USED} />
                          <FirstName prospectSource={prospectSource} firstNameUserInputValue={firstNameUserInputValue} setFirstNameUserInputValue={setFirstNameUserInputValue} setShowFirstNameError={setShowFirstNameError} formMethods={formMethods} isDisabled={false} isDuplicationTested={featuresActivated.IS_GUCCI_DIGITAL_ACCOUNT_USED} />
                        </NamesWrapper>
                      ) : (
                        <NamesWrapper>
                          <FirstName prospectSource={prospectSource} firstNameUserInputValue={firstNameUserInputValue} setFirstNameUserInputValue={setFirstNameUserInputValue} setShowFirstNameError={setShowFirstNameError} formMethods={formMethods} isDisabled={false} isDuplicationTested={featuresActivated.IS_GUCCI_DIGITAL_ACCOUNT_USED} />
                          <LastName prospectSource={prospectSource} lastNameUserInputValue={lastNameUserInputValue} setLastNameUserInputValue={setLastNameUserInputValue} setShowLastNameError={setShowLastNameError} formMethods={formMethods} isDisabled={false} isDuplicationTested={featuresActivated.IS_GUCCI_DIGITAL_ACCOUNT_USED} />
                        </NamesWrapper>
                      )
                  }
                {formMethods.errors.duplicationAlert?.message
                      && (
                      <ErrorMessage id="duplicationAlert">{formMethods.errors.duplicationAlert.message}</ErrorMessage>
                      )}
              </FormContainer>
              <NextButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.CUSTOMER_NAME} onSubmit={onSubmit} />
            </form>
          </FormProvider>
        </PageContainer>
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${actualToken}`} replace />;
};

export default CustomerName;
