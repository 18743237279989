import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import {
  Customer,
  CustomerLight,
  FeatureActivated,
  LightConfiguration,
  PageFlow,
  Phone,
  PreferredMethodOfContact,
  PrivacyConfiguration,
  ValidateOtpResponse
} from '@/types';
import { PreciselySearchAddressResponse } from '@/types/precisely';
import { PageID } from '@/types/enum/pageID';
import {
  ICheckCustomerByNameAndContactResponse,
  ICheckCustomerResponseV2,
  IPreciselyAuthTokenResponse,
  IResponseCheckPoP,
  IResponseDynamicTokenCreated,
  IResponseLineQrCodes,
  IResponseProspectSourceAndReCaptchaFrontKey,
  IResponseQRCode,
  IResponseUpdateSocialCustomer,
  IResponseUploadPoP,
  IResponseUpsertCustomer,
  ResponseGetCustomerFlow,
  ResponseGetFields,
  ResponseGetLegalConf
} from '@/types/api';
import { BrandCountryConfiguration } from '@/types/brandCountryConfiguration';
import { CheckCustomerData } from '@/types/form/customer-name-form.props';
import { FieldComponentEnum } from '@/types/enum/FieldComponent.enum';

const ws = axios.create({ withCredentials: true });
// URL suffix added only on this instance to avoid impact on other services
ws.defaults.baseURL = '/api';
ws.defaults.headers.common['request-date-time'] = moment().utc().format();

const api = {
  getProspectSourceAndReCaptchaFrontKey: (token: string = ''): Promise<AxiosResponse<IResponseProspectSourceAndReCaptchaFrontKey>> => ws.post(`GetProspectSourceAndReCaptchaFrontKey/${token}`),
  requestToken: (staticUuid: string = '', justPoseId: string = '', language: string = ''): Promise<AxiosResponse<IResponseDynamicTokenCreated>> => ws.post(`RequestToken/${staticUuid}`, {
    justPoseId,
    language
  }),
  fetchConf: (token: string = '', language: string = ''): Promise<{ data: LightConfiguration }> => {
    const route = `GetConf/light/${token}`;
    return (language ? ws.get(`${route}?languageCode=${language}`) : ws.get(route));
  },
  fetchBrandCountryConf: (token: string = '', countryCode: string = '', stateCode: string = ''): Promise<AxiosResponse<BrandCountryConfiguration>> => ws.get(
    `GetConf/brand/${token}?countryCode=${countryCode}&stateCode=${stateCode}`,
  ),
  checkCustomerV2: (customer: Customer, phone: Phone, email: string, brand: string, preferredMethodOfContact: PreferredMethodOfContact, token: string = '', reCaptchaToken: string = '', isOnlySecondPointOfContactChecked = false): Promise<AxiosResponse<ICheckCustomerResponseV2>> => ws.post(`customer/v2/Check/${token}?reCaptchaToken=${reCaptchaToken}`, {
    email,
    phone,
    preferredMethodOfContact,
    brand,
    storeid: customer.storeid,
    country: customer.customerContact.address.country,
    firstname: customer.customerInfo.firstname,
    title: customer.customerInfo.title,
    lastname: customer.customerInfo.lastname,
    isOnlySecondPointOfContactChecked
  }),
  requestOTP: (preferredMethodOfContact: PreferredMethodOfContact, phone: Phone, email: string, lang: string, token: string = ''): Promise<AxiosResponse<{ canResendOtp: boolean }>> => ws.post(`RequestOTP/${token}?lang=${lang}`, {
    preferredMethodOfContact,
    email,
    phone,
  }),
  registeredPageRequestOTP: (preferredMethodOfContact: PreferredMethodOfContact, phone: Phone, email: string, lang: string, token: string = ''): Promise<AxiosResponse<{ canResendOtp: boolean }>> => ws.post(`RegisteredPageRequestOTP/${token}?lang=${lang}`, {
    preferredMethodOfContact,
    email,
    phone,
  }),
  // if otp is correct, get the already existing customer information
  validateOTP: (preferredMethodOfContact: PreferredMethodOfContact, otp: number, phone: Phone, email: string, brand: string, oktaLogin?: string, token: string = ''): Promise<AxiosResponse<ValidateOtpResponse>> => ws.post(`ValidateOTP/${token}?otp=${otp}`, {
    preferredMethodOfContact,
    email,
    phone,
    brand,
    oktaLogin
  }),
  // if otp is correct, get the already existing customer information
  registeredPageValidateOTP: (preferredMethodOfContact: PreferredMethodOfContact, otp: number, phone: Phone, email: string, brand: string, oktaLogin?: string, token: string = ''): Promise<AxiosResponse<ValidateOtpResponse>> => ws.post(`RegisteredPageValidateOTP/${token}?otp=${otp}`, {
    preferredMethodOfContact,
    email,
    phone,
    brand,
    oktaLogin
  }),
  fetchPrivacyConf: (token: string = '', country: string = '', state: string = '', language: string = ''): Promise<AxiosResponse<PrivacyConfiguration>> => ws.get(
    `GetConf/privacy/${token}?countryCode=${country}&stateCode=${state}&languageCode=${language}`,
  ),
  upsertCustomer: (customer: Customer, token: string = ''): Promise<AxiosResponse<IResponseUpsertCustomer>> => ws.post(`customer/Upsert/${token}`, { ...customer }),
  createOktaCustomer: (customer: Customer, token: string = ''): Promise<AxiosResponse<{ oktaLogin: string }>> => ws.post(`customer/createOktaCustomer/${token}`, { ...customer }),
  fetchLineQrCode: (token: string = ''): Promise<AxiosResponse<IResponseQRCode>> => ws.get(`GetQRCode/Line/${token}`),
  fetchWeChatQrCode: (token: string = ''): Promise<AxiosResponse<IResponseQRCode>> => ws.get(`GetQRCode/WeChat/${token}`),
  fetchVCardQrCode: (token: string = '', body: { vCardFormattedString: string }): Promise<AxiosResponse<IResponseQRCode>> => ws.post(`GetQRCode/VCard/${token}`, body),
  fetchLineJpThTwQrCodes: (token: string = ''): Promise<AxiosResponse<IResponseLineQrCodes>> => ws.get(`GetQRCode/LineJpThTw/${token}`),
  getVCard: (token: string = ''): Promise<AxiosResponse<string>> => ws.get(`GetVCard/${token}`),
  preciselySearchAddress: (preciselyToken: string, country: string = '', state: string = '', userInput: string = '', instanceKey: string = ''): Promise<{data: PreciselySearchAddressResponse}> => ws.get(
    `https://token-emea.spectrum.precisely.com/rest/GlobalTypeAheadInternational/results.json?Data.Country=${country}&Data.StateProvince=${state}&Data.AddressLine1=${userInput}&Data.InstanceKey=${instanceKey}`,
    {
      transformRequest: [(data, headers) => { delete headers.common['request-date-time']; return data; }],
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${preciselyToken}`,
      },
      withCredentials: true,
    },
  ),
  getPartialUpdateInformation: (token: string = ''): Promise<AxiosResponse<CustomerLight>> => ws.get(`customer/GetPartialUpdateInformation/${token}`),
  logFrontError: (token: string = '', page: PageID = PageID.WELCOME): Promise<void> => ws.get(`FrontError/${token}?page=${page}`),
  logInformation: (token: string = '', level: string = '', message: string = ''): Promise<void> => ws.get(`LogInformation/${token}?level=${level}&message=${message}`),
  getPreciselyAuthToken: (token: string = ''): Promise<IPreciselyAuthTokenResponse> => ws.get(`/Precisely/auth/${token}`).then((response) => response.data),
  getFeatureActivationAndPageFlow: (token: string = '', countryOfResidence: string = ''): Promise<AxiosResponse<{ featuresActivated: { [key in FeatureActivated]: boolean }, pageFlow: PageFlow }>> => ws.get(
    `GetFeatureActivationAndPageFlow/${token}?countryOfResidence=${countryOfResidence}`,
  ),
  checkPoP: (token: string = ''): Promise<IResponseCheckPoP> => ws.get(`CheckPoP/${token}`),
  uploadPoP: (token: string = '', data: FormData): Promise<IResponseUploadPoP> => ws.post(`uploadPoP/${token}`, data),
  addWeChatSocialChannelToDynamicToken: (token: string = '', weChatSocialChannel: { weChat: { userId: string, weChatLastSubscriptionDate: string }, weChatPlatform: string}): Promise<void> => ws.post(`weChat/${token}`, weChatSocialChannel),
  checkCustomerByNameAndContact: (customerData: CheckCustomerData, token: string = ''): Promise<AxiosResponse<ICheckCustomerByNameAndContactResponse>> => ws.post(`customer/CheckCustomerByNameAndContact/${token}`, { customerData }),
  getCustomerFlow: (token: string = ''): Promise<ResponseGetCustomerFlow> => ws.get(`/getCustomerFlow/${token}`),
  getFields: (languageCode: string, customerCountry: string, token: string = '', names?: (string | FieldComponentEnum)[]): Promise<ResponseGetFields> => ws.get(`/WireApi/GetFields/${token}?&languageCode=${languageCode}&customerCountry=${customerCountry}${names && `&names=${names.join('&names=')}`}`),
  getLegalConf: (languageCode: string, customerCountry: string, customerState: string, token: string = ''): Promise<AxiosResponse<ResponseGetLegalConf>> => ws.get(`/WireApi/GetLegalConf/${token}?&languageCode=${languageCode}&customerCountry=${customerCountry}&customerState=${customerState}`),
  updateSocial: (customer: Customer, token: string = ''): Promise<AxiosResponse<IResponseUpdateSocialCustomer>> => ws.post(`customer/UpdateSocial/${token}`, { ...customer }),
};

export default api;
