import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { Street } from '@pages/Address/components/Street';
import { City } from '@pages/Address/components/City';
import { PoBox } from '@pages/Address/components/PoBox';
import { ZipCode } from '@pages/Address/components/ZipCode';
import { SearchOptionsContainer, SearchWrapper } from '@pages/Address/address.styles';
import { Country, StateCountry, TYPE_DROPDOWN } from '@/types';
import {
  updateCustomerInformation,
  updateIsLoading,
  updatePreciselyAuthToken,
  updatePreciselyInstanceKey
} from '@/littleStateMachine/actions';
import { SearchBar, Title } from '@/common/components';
import { NextButtonContainer, FormContainer, PageContainer } from '@/common/components/containers';
import { Label, Select } from '@/common/components/form';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import mp from '@/services/mixpanel/mixpanel.service';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import FormHeader from '@/common/components/form/FormHeader';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import api from '@/api';
import { NOT_ENOUGH_INPUT, PreciselySearchAddressResponseEntry } from '@/types/precisely';
import { Widget } from '@/common/components/SearchBar';
import { SelectSearchCustomButton } from '@/common/components/form/Select';
import { FullPageLoader } from '@/common/components/Loader';

const Address: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.ADDRESS);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { actions, state } = useStateMachine({ updateCustomerInformation, updatePreciselyAuthToken, updatePreciselyInstanceKey, updateIsLoading });
  const { brand, countries, customer, prospectSource, flow, translations, languages, featuresActivated, precisely, isLoading, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  // Prepare selected country & state
  const customerCountry = countries.find((country: Country) => country.code === customer.customerContact.address.country);
  if (customerCountry) {
    customerCountry.name = t(`country.${customer.customerContact.address.country.toLocaleLowerCase()}`);
  }

  const customerState = customerCountry?.states && customerCountry.states.find((countryState: StateCountry) => countryState.code === customer.customerContact.address.state);
  if (customerState) {
    customerState.name = t(`state.${customer.customerContact.address.state}`);
  }

  const [showZipCodeError, setShowZipCodeError] = useState(false);
  const [showCityError, setShowCityError] = useState(false);
  const [showStreetError, setShowStreetError] = useState(false);
  const [search, setSearch] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const [preciselyEntries, setPreciselyEntries] = useState<PreciselySearchAddressResponseEntry[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // current page
  const localStep = PageID.ADDRESS;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  const handleMouseDown = async (preciselyEntry: PreciselySearchAddressResponseEntry) => {
    formMethods.setValue('street', preciselyEntry.AddressLine1);
    formMethods.setValue('city', preciselyEntry.City);
    formMethods.setValue('zipCode', preciselyEntry.PostalCode);
    await formMethods.trigger();
  };

  const onSubmit = formMethods.handleSubmit(async ({
    street, city, zipCode, pobox,
  }) => {
    const addressInfos = { street, city, zipCode, pobox };
    actions.updateCustomerInformation(addressInfos);
    mp.saveAddress(addressInfos);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  const upsertPreciselyAuthToken = async () => {
    try {
      const { access_token: accessToken } = await api.getPreciselyAuthToken(token);
      actions.updatePreciselyAuthToken(accessToken);
    } catch (e) {
      // precisely auth error
    }
  };

  useEffect(() => {
    if (featuresActivated.IS_ADDRESS_SEARCH_ACTIVATED) {
      // fetch precisely auth token (10 mins validity)
      (async function asyncFunction() {
        // fetch only if we don't have one already
        if (!precisely.authToken) {
          actions.updateIsLoading(true);
          await upsertPreciselyAuthToken();
          actions.updateIsLoading(false);
        }
      }());
    }
  }, []);

  useEffect(() => {
    // use precisely api to prefill address
    let timeout: NodeJS.Timeout;
    if (precisely.authToken) {
      // Wait 400ms after each onChange event before sending request
      timeout = setTimeout(async () => {
        try {
          const { country: addressCountry, state: addressState } = customer.customerContact.address;
          const { data: searchAddressResponse } = await api.preciselySearchAddress(precisely.authToken, addressCountry, addressState && t(`state.${addressState.toLocaleLowerCase()}`), search, precisely.instanceKey);
          if (!precisely.instanceKey) actions.updatePreciselyInstanceKey(searchAddressResponse.Output[0]?.InstanceKey);
          // security if an old request takes too much time to arrive, and we already have made a new request. Use a workaround to get the UI instant real value of the search bar with getElementById.
          // @ts-ignore
          if (document?.getElementById('searchAddress')?.value === search) {
            // ignore not enough input entry
            setPreciselyEntries(searchAddressResponse.Output.filter((preciselyEntry: PreciselySearchAddressResponseEntry) => preciselyEntry['Status.Code'] !== NOT_ENOUGH_INPUT));
          }
        } catch (err) {
          if (err.isAxiosError && [403, 401].includes(err.response.status)) {
            await upsertPreciselyAuthToken();
          }
        }
      }, 400);
    }
    return () => {
      clearTimeout(timeout);
    };
    // triggers precisely update after each "search" change
  }, [search]);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, prospectSource }}>
        { isLoading ? <FullPageLoader /> : (
          <PageContainer>
            <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
            <Title>{t('dcc.addressQuestion')}</Title>
            {Boolean(featuresActivated.IS_ADDRESS_SEARCH_ACTIVATED) && (
            <SearchWrapper>
              <SearchBar
                windowWidth={windowWidth}
                id="searchAddress"
                name="searchAddress"
                placeholder={t('web.searchAddress')}
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
                onCrossClick={() => setSearch('')}
                widget={Widget.CROSS}
              />
              {Boolean(preciselyEntries.length && searchFocus) && (
              <SearchOptionsContainer>
                {
                  preciselyEntries
                    .map((preciselyEntry: PreciselySearchAddressResponseEntry) => (
                      <SelectSearchCustomButton
                        type="button"
                        value={preciselyEntry.FormattedAddress}
                        key={preciselyEntry.FormattedAddress}
                        onMouseDown={() => handleMouseDown(preciselyEntry)}
                      >
                        {preciselyEntry.FormattedAddress}
                      </SelectSearchCustomButton>
                    ))
                }
              </SearchOptionsContainer>
              )}
            </SearchWrapper>
            )}
            <FormProvider {...formMethods}>
              <form>
                <FormContainer onKeyDown={(event) => Utils.validateForm(event, isValid, onSubmit)}>
                  { // Country pre-filled with selected country at page 1
                  customerCountry && (
                    <>
                      <Label htmlFor="country">{t('dcc.countryLabel')}</Label>
                      <Select
                        name="country"
                        id="country"
                        parentRef={formMethods.register({ required: true })}
                        defaultValue={customerCountry.code}
                        disabled
                      >
                        <option value={customerCountry.code} key={customerCountry.code}>{customerCountry.name}</option>
                      </Select>
                    </>
                  )
                }
                  { // State pre-filled with selected state at page 2 (if states exist in current country)
                  customerState && (
                    <>
                      <Label htmlFor="state">{customerCountry?.hasStates ? t('dcc.state') : t('dcc.province')}</Label>
                      <Select
                        name="countryState"
                        id="countryState"
                        parentRef={formMethods.register({ required: true })}
                        defaultValue={customerState.code}
                        disabled
                      >
                        <option value={customerState.code} key={customerState.code}>{customerState.name}</option>
                      </Select>
                    </>
                  )
                }
                  { // the normal if/else workflow breaks the mandatory state of fields. Due to the form control of react-hook-form ?
                  customerCountry && Utils.isAddressReversed(translations.lightLanguageCode, customerCountry.code)
                  && (
                    <>
                      <ZipCode prospectSource={prospectSource} currentCountry={customerCountry} showZipCodeError={showZipCodeError} setShowZipCodeError={setShowZipCodeError} />
                      <City prospectSource={prospectSource} showCityError={showCityError} setShowCityError={setShowCityError} />
                      <Street prospectSource={prospectSource} showStreetError={showStreetError} setShowStreetError={setShowStreetError} />
                      <PoBox currentCountry={customerCountry} />
                      {((showZipCodeError && formMethods.errors?.zipCode) || (showCityError && formMethods.errors?.city) || (showStreetError && formMethods.errors?.street)) && <ErrorMessage id="error">{t('web.invalidFormat')}</ErrorMessage>}
                    </>
                  )
                }
                  {
                  customerCountry && !Utils.isAddressReversed(translations.lightLanguageCode, customerCountry.code)
                  && (
                    <>
                      <Street prospectSource={prospectSource} showStreetError={showStreetError} setShowStreetError={setShowStreetError} />
                      <PoBox currentCountry={customerCountry} />
                      <City prospectSource={prospectSource} showCityError={showCityError} setShowCityError={setShowCityError} />
                      <ZipCode prospectSource={prospectSource} currentCountry={customerCountry} showZipCodeError={showZipCodeError} setShowZipCodeError={setShowZipCodeError} />
                      {((showZipCodeError && formMethods.errors?.zipCode) || (showCityError && formMethods.errors?.city) || (showStreetError && formMethods.errors?.street)) && <ErrorMessage id="error">{t('web.invalidFormat')}</ErrorMessage>}
                    </>
                  )
                }
                </FormContainer>
                <NextButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.ADDRESS} onSubmit={onSubmit} />
              </form>
            </FormProvider>
          </PageContainer>
        )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default Address;
