import React from 'react';
import { useController, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field, OptionalLegend } from '@common/components/form/Field';
import { ConnectForm, ErrorMessage, Label } from '@common/components/form';
import Utils from '@utils/utils';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponentEnum } from '@/types/enum/FieldComponent.enum';

type Props = {
  name: string,
  index: number,
  defaultValue?: string
}

export const FloorAptNb: React.FC<Props> = ({ name, index, defaultValue }) => (
  <ConnectForm>
    {({ errors, t, control, fieldsConfiguration }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const floorAptNbFieldInfos = Utils.getFieldComponent(fieldsConfiguration, TextFieldComponentEnum.FLOOR_APT_NB);

      const { field, meta } = useController({ name,
        rules: {
          required: floorAptNbFieldInfos?.isMandatory,
          pattern: Utils.stringToRegex(floorAptNbFieldInfos?.regex),
          validate: (value) => Utils.doesNotContainOnlySpaces(value)
        },
        control,
        defaultValue: defaultValue || ''
      });

      const fieldError = errors.addresses?.[index]?.floorAptNb;

      return (
        <>
          <Label htmlFor={name}>
            {t('web.floorAptNumber')}
          </Label>
          {!floorAptNbFieldInfos?.isMandatory && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
          <Field
            id={name}
            {...field}
            disabled={!floorAptNbFieldInfos?.isEditable}
            hasError={Boolean(fieldError && meta.isTouched)}
          />
          {fieldError && (<ErrorMessage>{t('web.invalidFormat')}</ErrorMessage>)}
        </>
      );
    }}
  </ConnectForm>
);
