import React, { Dispatch, SetStateAction } from 'react';
import { useStateMachine } from 'little-state-machine';
import {
  CloseModalContainer,
  SocialLoginModalBody,
  SocialLoginModalContainer, Title
} from '@pages/CountryPage/components/socialLoginModal.style';
import Modal from '@common/components/modals/Modal';
import ContinueWithWireButton from '@pages/CountryPage/components/ContinueWithWireButton';
import KakaoButton from '@pages/CountryPage/components/SocialLoginButtons/KakaoButton';
import AppleButton from '@pages/CountryPage/components/SocialLoginButtons/AppleButton';
import CrossIcon from '@assets/img/icons/CrossIcon';
import HalfLine from '@pages/CountryPage/components/HalfLine';
import { useTranslation } from '@services/hooks';
import { PageID } from '@/types/enum/pageID';

interface SocialLoginModalProps {
  localStep: PageID,
  setSocialLoginModalDisplayed: Dispatch<SetStateAction<boolean>>,
}

const SocialLoginModal = ({
  setSocialLoginModalDisplayed,
  localStep,
}: SocialLoginModalProps) => {
  const { state } = useStateMachine();
  const { featuresActivated } = state;
  const t = useTranslation();

  return (
    <SocialLoginModalContainer>
      <Modal onClose={() => setSocialLoginModalDisplayed(false)} isBottomModal>
        <SocialLoginModalBody>
          <CloseModalContainer>
            <CrossIcon />
          </CloseModalContainer>
          <Title>
            <HalfLine />
            <span>{t('web.youCanRegisterWith')}</span>
            <HalfLine />
          </Title>
          {featuresActivated.IS_KAKAO_FLOW_ACTIVATED && <KakaoButton localStep={localStep} />}
          {featuresActivated.IS_APPLE_SOCIAL_LOGIN_ACTIVATED && <AppleButton localStep={localStep} />}
          <ContinueWithWireButton localStep={localStep} />
        </SocialLoginModalBody>
      </Modal>
    </SocialLoginModalContainer>
  );
};

export default SocialLoginModal;
