import styled from 'styled-components';
import React from 'react';

const HalfLine: React.FC = () => (
  <StyledHalfLine xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 2">
    <path d="M0 1H49.5" vectorEffect="non-scaling-stroke" />
  </StyledHalfLine>
);

const StyledHalfLine = styled.svg`
    stroke-width: 1px;
    stroke: ${(props) => props.theme.colors.COMMON.button.disabled.borderColor};
    width: 100%;
`;

export default HalfLine;
