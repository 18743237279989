import React, { useEffect, useState } from 'react';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { Email } from '@pages/Contact/components/Email';
import { ActionButton, RemoveItemButton } from '@common/components/form/Button';
import { SectionTitle } from '@pages/Information/information.styles';
import { Radio } from '@pages/UpdateNewPosCustomer/components/Radio';
import {
  EmailItemContainer,
  EmailSectionContainer,
  EmailsItemContainer,
  PrimaryContainer
} from '@common/components/containers/FormContainer';
import Label from '@common/components/form/Label';
import { EmailType } from '@pages/UpdateNewPosCustomer/components/Email/EmailType';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ConnectForm } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ProspectSource } from '@/types';
import { PageID } from '@/types/enum/pageID';
import { EmailTypeEnum } from '@/types/enum/email-type.enum';
import { TextFieldComponent } from '@/types/api';

type Props = {
  prospectSource: ProspectSource,
  formMethods: UseFormMethods<InformationFormProps>,
  localStep: PageID,
  emailField: TextFieldComponent
}

export const Emails: React.FC<Props> = ({
  emailField,
  prospectSource,
  formMethods,
  localStep
}) => (
  <ConnectForm>
    {({ control, t, register, setValue, getValues, unregister }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { fields, remove, append } = useFieldArray({ name: 'emails', control });

      const [showEmailError, setShowEmailError] = useState(false);
      const [emailError, setEmailError] = useState(false);
      const addEmail = () => {
        append({ address: '', type: EmailTypeEnum.EMPTY, primary: false });
      };

      const removeEmail = (index: number) => {
        remove(index);
      };

      useEffect(() => {
        const primaryIndex = fields.findIndex((fieldToFind) => fieldToFind.primary === true);
        updatePrimaryEmail(primaryIndex);
      }, [fields]);

      const updatePrimaryEmail = (index: number) => {
        // Set all emails primary attribute to false except the one selected
        fields.forEach((_, emailObjIndex) => {
          setValue(`emails[${emailObjIndex}].primary`, false);
          unregister(`emails[${emailObjIndex}].address`);
        });
        register(`emails[${index}].address`, { required: true });
        setValue(`emails[${index}].primary`, true);
      };

      return (
        <>
          <SectionTitle>Emails</SectionTitle>
          <EmailSectionContainer>
            <EmailsItemContainer>
              {fields.map((field, index) => {
                const emailName = `emails[${index}].address` as `emails[${number}].address`;
                const emailType = `emails[${index}].type` as `emails[${number}].type`;
                return (
                  <EmailItemContainer key={field.id}>
                    <EmailType
                      name={emailType}
                      defaultValue={field.type}
                    />
                    <Email
                      emailField={emailField}
                      isNewPosEmail
                      emailUserInputValue={field.address}
                      isPrimaryEmail={getValues(`emails[${index}].primary`)}
                      key={`email.${index}`}
                      fieldName={emailName}
                      formMethods={formMethods}
                      prospectSource={prospectSource}
                      localStep={localStep}
                      showEmailError={showEmailError}
                      setShowEmailError={setShowEmailError}
                      emailError={emailError}
                      setEmailError={setEmailError}
                    />

                    {!getValues(`emails[${index}].primary`)
                    && <RemoveItemButton name={`emails[${index}].remove`} key={`remove.${index}`} onClick={() => removeEmail(index)}>-</RemoveItemButton>}
                  </EmailItemContainer>
                );
              })}
            </EmailsItemContainer>
            <PrimaryContainer>
              <Label>{t('dcc.primary')}</Label>
              {fields.map((_, index) => (
                <div key={`radio.div.${index}`}>
                  <Radio name={`emails[${index}].primary`} key={`radio.${index}`} index={index} onChange={updatePrimaryEmail} height={45} />
                </div>
              ))}
            </PrimaryContainer>
          </EmailSectionContainer>

          {/* type="button" prevent the page from reloading on click */}
          <ActionButton name="addEmailButton" type="button" onClick={() => addEmail()}>{t('web.addItem')}</ActionButton>
        </>
      );
    }}
  </ConnectForm>
);
