import React, { Dispatch, SetStateAction } from 'react';
import { LegalTextAndDefaultCountry } from '@pages/CountryPage/components/LegalTextAndDefaultCountry';
import Utils from '@utils/utils';
import { CountriesContainer, IndexLetter } from '@pages/CountryPage/countryPage.styles';
import CountryService from '@pages/CountryPage/country.service';
import { useStateMachine } from 'little-state-machine';
import { useNavigate } from 'react-router-dom';
import SocialLoginModal from '@pages/CountryPage/components/SocialLoginModal';
import { CountryStateButton } from '@/common/components';
import { Country, IndexedCountries } from '@/types';
import {
  updateBrandCountryConfiguration,
  updateCustomerCountry,
  updateErrorTypeOnError,
  updateFeaturesActivationAndPageFlow,
  updateIsLoading
} from '@/littleStateMachine/actions';
import { PageID } from '@/types/enum/pageID';

type Props = {
  isWriting: boolean,
  defaultCountry?: Country,
  indexedCountries: IndexedCountries,
  search: string,
  localStep: PageID,
  token: string,
  socialLoginModalDisplayed: boolean,
  setSocialLoginModalDisplayed: Dispatch<SetStateAction<boolean>>,
};

export const CountrySearchResults: React.FC<Props> = ({ isWriting, defaultCountry, indexedCountries, search, localStep, token, socialLoginModalDisplayed, setSocialLoginModalDisplayed }) => {
  const { actions, state } = useStateMachine({ updateCustomerCountry, updateBrandCountryConfiguration, updateErrorTypeOnError, updateFeaturesActivationAndPageFlow, updateIsLoading });
  // Prepare navigation
  const navigate = useNavigate();
  return (
    <>
      <LegalTextAndDefaultCountry
        isWriting={isWriting}
        defaultCountry={defaultCountry}
        localStep={localStep}
        token={token}
        setSocialLoginModalDisplayed={setSocialLoginModalDisplayed}
      />
      {Object.keys(indexedCountries).map((indexLetter: string) => {
        const filteredIndexedCountries = indexedCountries[indexLetter].filter((country: Country) => RegExp(Utils.escapeRexExSpecialChars(search), 'i').test(Utils.normalize(country.name)));
        return Boolean(filteredIndexedCountries.length) && (
          <CountriesContainer key={indexLetter}>
            {!isWriting && <IndexLetter>{indexLetter}</IndexLetter>}
            {
              filteredIndexedCountries.map((country: Country) => (
                <CountryStateButton
                  value={country.code}
                  key={country.code}
                  type="button"
                  onMouseDown={() => CountryService.onCountryButtonClick(country, actions.updateCustomerCountry, actions.updateBrandCountryConfiguration, actions.updateErrorTypeOnError, actions.updateFeaturesActivationAndPageFlow, actions.updateIsLoading, state, localStep, token, navigate, setSocialLoginModalDisplayed)}
                >
                  {`${country.name}`}
                </CountryStateButton>
              ))
            }
          </CountriesContainer>
        );
      })}
      {socialLoginModalDisplayed && <SocialLoginModal setSocialLoginModalDisplayed={setSocialLoginModalDisplayed} localStep={localStep} />}
    </>
  );
};
