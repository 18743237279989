import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import Utils from '@utils/utils';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

type Props = {
  furiganaLastName: TextFieldComponent;
}

export const FuriganaLastName: React.FC<Props> = ({ furiganaLastName }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="furiganaLastName">{t('dcc.furigana_lastname')}</Label>
        <OptionalLegend>{t('dcc.optional')}</OptionalLegend>
        <Field
          name="furiganaLastName"
          id="furiganaLastName"
          ref={register({ required: furiganaLastName.isMandatory,
            pattern: Utils.stringToRegex(furiganaLastName.regex)
          })}
          defaultValue={customer.customerInfo.furigana_lastname}
          disabled={!furiganaLastName.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
