import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import Utils from '@utils/utils';
import {
  CustomRadio,
  RadioLabel,
  RadiosContainer,
  RadioStringLabel,
  StyledRadioInput,
} from '@pages/Contact/contact.styles';
import ContactService from '@pages/Contact/contact.service';
import { PhoneAndEmail } from '@pages/Contact/components/PhoneAndEmail';
import { ContactTitle } from '@pages/Contact/components/ContactTitle';
import { ZipCode } from '@pages/Address/components/ZipCode';
import { Street } from '@pages/Address/components/Street';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import kakaoApiService from '@services/kakao-api.service';
import { FullPageLoader } from '@common/components/Loader';
import KakaoUtils from '@utils/kakao.utils';
import EmailValidityUtils from '@utils/EmailValidity.utils';
import { FormWarningContext, FormWarningProps } from '@common/components/form/FormWarningContext';
import SubTitle from '@/common/components/SubTitle';
import api from '@/api';
import {
  Country,
  FeatureActivated,
  FlowName,
  PageFlow,
  PreferredMethodOfContact,
  ProspectSource,
  radioInputs,
  TYPE_DROPDOWN
} from '@/types';
import {
  emptyCustomerData,
  updateAlreadyRegistered,
  updateBrandCountryConfiguration,
  updateCustomerInformation,
  updateDateSendingOtp,
  updateDropdownSearchValuesPrefixNumber,
  updateErrorTypeOnError,
  updateFeaturesActivationAndPageFlow,
  updateIsSocialUserRetrieved,
  updateIsLoading,
  updateEventId
} from '@/littleStateMachine/actions';
import PageContainer from '@/common/components/containers/PageContainer';
import FormContainer from '@/common/components/containers/FormContainer';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import mp from '@/services/mixpanel/mixpanel.service';
import { NextButtonContainer } from '@/common/components/containers';
import QueueManagementRedirectButton, { QueueCreationState } from '@/common/components/QueueManagementRedirectButton';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { RadioInput } from '@/types/radioInput';
import FormHeader from '@/common/components/form/FormHeader';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import { LogLevelEnum } from '@/types/enum/log-level.enum';
import { LogMessageEnum } from '@/types/enum/log-message.enum';
import { CustomerCheckResponseEnum } from '@/types/api';
import { EmailValidityStatusEnum } from '@/types/enum/email-validity-status.enum';

const Contact: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.CONTACT);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { actions, state } = useStateMachine({
    updateErrorTypeOnError,
    updateDateSendingOtp,
    updateDropdownSearchValuesPrefixNumber,
    updateAlreadyRegistered,
    updateCustomerInformation,
    updateIsSocialUserRetrieved,
    updateIsLoading,
    updateFeaturesActivationAndPageFlow,
    updateBrandCountryConfiguration,
    emptyCustomerData,
    updateEventId
  });
  const { brand, countries, customer, prospectSource, flow, translations, languages, featuresActivated, event, isSocialUserRetrieved, actualToken, isLoading, mixPanelToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  const customerCountry = customer.customerContact.address.country;

  // current page
  const localStep = PageID.CONTACT;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;
  const { duplicationAlert } = formMethods.errors;

  // As customerContact.phone is an array, we need to check it is not empty to retrieve phone data
  const { phone } = customer.customerContact;
  const emailLocal = customer.customerContact.email;
  const prefixCountryLocal = phone.prefixCountry ?? '';
  const number = phone.number || '';

  // local states
  const phoneCountryInitValueDefault = prefixCountryLocal || customer.customerContact.address.country;
  const { phonePrefixInitValue, phoneCountryInitValue } = Utils.getPhonePrefixAndCountry(phoneCountryInitValueDefault, countries);
  const [phonePrefixLabelLocal, setPhonePrefixLabelLocal] = useState(`${phonePrefixInitValue} (${phoneCountryInitValue})`);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [phoneUserInputValue, setPhoneUserInputValue] = useState(number);
  const [emailUserInputValue, setEmailUserInputValue] = useState(emailLocal || '');
  const [preferredMethodOfContactLocal, setPreferredMethodOfContactLocal] = useState(ContactService.getPreferedMethodOfContact(featuresActivated, customer, brand));
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [lastWebAppCheckResult, setLastWebAppCheckResult] = useState<CustomerCheckResponseEnum>();
  const [lastEmailValidity, setEmailValidity] = useState<EmailValidityStatusEnum>();
  let radioInputsList: RadioInput[] = radioInputs;
  if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED || featuresActivated.IS_GUCCI_CREATE_USED) radioInputsList = radioInputsList.filter((radio) => (radio.value !== PreferredMethodOfContact.PHONE_AND_EMAIL));
  // GUCCI specificity : set EMAIL as preferred method of contact when WIRE_EDIT is enable for GUCCI brand
  if (Utils.isEmailFirstOnWireEditFlow(featuresActivated, brand)) radioInputsList.unshift(radioInputsList.pop()!);
  if (featuresActivated.IS_PHONE_CONTACT_METHOD_DISABLED) radioInputsList = radioInputsList.filter((radio) => (radio.value !== PreferredMethodOfContact.PHONE));

  const [warning, setWarning] = useState<string>('');
  const cancelProceedAttempt = () => {
    setLastWebAppCheckResult(undefined);
    setWarning('');
  };
  const formWarningValue: FormWarningProps = { riskyEmailAlert: warning, cancelProceedAttempt };

  const translatedCountries = countries
    .map((country: Country) => ({ ...country, name: t(`country.${country.code.toLocaleLowerCase()}`) }))
    .filter((country: Country) => country.phonePrefix !== undefined)
    .sort((a: Country, b: Country) => a.name.localeCompare(b.name));

  // prechecked default radio button (handle the case when user go back to the page)
  radioInputsList.forEach((input) => { input.prechecked = false; });
  if (Utils.isEmailFirstOnWireEditFlow(featuresActivated, brand)) {
    const precheckedInput = radioInputsList.find((input) => input.value === PreferredMethodOfContact.EMAIL);
    if (precheckedInput) {
      precheckedInput.prechecked = true;
    }
  } else if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED) {
    const precheckedInput = radioInputsList.find((input) => input.value === PreferredMethodOfContact.PHONE);
    if (precheckedInput) {
      precheckedInput.prechecked = true;
    }
  } else if (customer.customerContact.preferred) {
    const precheckedInput = radioInputsList.find((input) => input.value === customer.customerContact.preferred);
    if (precheckedInput) {
      precheckedInput.prechecked = true;
    }
  } else {
    const precheckedInput = radioInputsList.find((input) => input.value === PreferredMethodOfContact.PHONE_AND_EMAIL);
    if (precheckedInput) {
      precheckedInput.prechecked = true;
    }
  }

  // We add legal page from page flow if it's not already present
  FlowService.addPageInFlow(state, { name: PageID.LEGAL, stepValue: 1 }, FlowService.getIndex(state, PageID.INFORMATION) + 1);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      // Usefull when the customer go back on contact page after otp
      if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && !isSocialUserRetrieved && prospectSource !== ProspectSource.WEBDCC_POS) {
        actions.updateAlreadyRegistered(false);
        actions.emptyCustomerData(undefined);
      }
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        const code = KakaoUtils.retrieveCode();
        actions.updateIsLoading(true);
        if (code && !isSocialUserRetrieved) {
          try {
            const response = await kakaoApiService.retrieveKakaoUser(actualToken, brand.code, code, `${window.location.origin}/kakao/contact`);
            const kakaoData = KakaoUtils.prefillWithKakaoData(state, actions.updateCustomerInformation, response.data);
            setEmailUserInputValue(kakaoData.email);
            setPhoneUserInputValue(kakaoData.phone.number);
            setPhonePrefixLabelLocal(`${kakaoData.phone.prefix} (${kakaoData.phone.prefixCountry})`);
            actions.updateIsSocialUserRetrieved(true);
          } catch (err) {
            await api.logInformation(state.actualToken, LogLevelEnum.ERROR, LogMessageEnum.KAKAO_GET_USER_ERROR);
          }
          navigate(`/${actualToken}/${localStep.toLowerCase()}`);
        }
        actions.updateIsLoading(false);
        // It prevents the submit button to be disabled if user goes back from the next page or if he refreshes the page.
        await formMethods.trigger();
        // Show errors in case of prefilled data
        if ((featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED) && preferredMethodOfContactLocal === PreferredMethodOfContact.PHONE) {
          ContactService.setShowErrorIfFormError(formMethods, setShowPhoneError, 'phoneNum', setPhoneError, setEmailError, preferredMethodOfContactLocal);
        } else if ((featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED) && preferredMethodOfContactLocal === PreferredMethodOfContact.EMAIL) {
          ContactService.setShowErrorIfFormError(formMethods, setShowEmailError, 'email', setPhoneError, setEmailError, preferredMethodOfContactLocal);
        } else {
          ContactService.setShowErrorIfFormError(formMethods, setShowPhoneError, 'phoneNum', setPhoneError, setEmailError, preferredMethodOfContactLocal);
          ContactService.setShowErrorIfFormError(formMethods, setShowEmailError, 'email', setPhoneError, setEmailError, preferredMethodOfContactLocal);
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token, customerCountry]);

  // Check if the ReCaptcha and the execute function are ready
  const script = document.querySelector('#google-recaptcha-v3');
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    (async function fetch() {
      if (Utils.shouldDisplayRecaptcha(prospectSource)) await api.logInformation(token, LogLevelEnum.INFO, script ? LogMessageEnum.RECAPTCHA_SCRIPT_LOADED : LogMessageEnum.RECAPTCHA_SCRIPT_NOT_LOADED);
    }());
  }, [script, token, prospectSource]);

  useEffect(() => {
    (async function fetch() {
      if (Utils.shouldDisplayRecaptcha(prospectSource)) await api.logInformation(token, LogLevelEnum.INFO, executeRecaptcha ? LogMessageEnum.RECAPTCHA_FUNCTION_READY : LogMessageEnum.RECAPTCHA_FUNCTION_NOT_READY);
    }());
  }, [executeRecaptcha, token, prospectSource]);

  // submit form
  const onSubmit = formMethods.handleSubmit(async (informationFormProps: InformationFormProps) => {
    const { phonePrefixLabel, phoneNum = '', email = '', preferredMethodOfContact } = informationFormProps;
    actions.updateDropdownSearchValuesPrefixNumber(phonePrefixLabel);
    const { phonePrefix } = ContactService.getPhonePrefix(phonePrefixLabel);
    let reCaptchaToken = '';
    if (executeRecaptcha && Utils.shouldDisplayRecaptcha(prospectSource)) {
      reCaptchaToken = await executeRecaptcha('contactPage');
    }
    try {
      // Check if a customer already exists with the same phone number or email
      const { data } = await api.checkCustomerV2(
        customer,
        {
          prefix: phonePrefix,
          number: phoneNum,
        },
        email,
        brand.code_CDB,
        preferredMethodOfContact,
        actualToken,
        reCaptchaToken
      );

      const { webAppCheckResult, oktaResult: { contactCertified, customerExistsInOkta }, emailValidity, eventId } = data;
      actions.updateEventId(eventId);

      let features = state.featuresActivated;
      let pageFlow: PageFlow = state.flow;
      if (!event.isCustomJourney && webAppCheckResult !== CustomerCheckResponseEnum.DUPLICATION_ALERT) {
        const {
          data: {
            featuresActivated: featuresFromBackend,
            pageFlow: pageFlowFromBackend
          }
        } = await api.getFeatureActivationAndPageFlow(token, customer.customerContact.address.country);
        features = featuresFromBackend;
        pageFlow = pageFlowFromBackend;
        actions.updateFeaturesActivationAndPageFlow({
          featuresActivated: featuresFromBackend,
          pageFlow: pageFlowFromBackend
        });
        const { data: payload } = await api.fetchBrandCountryConf(token, state.customer.customerContact.address.country);
        actions.updateBrandCountryConfiguration(payload);
      }

      setLastWebAppCheckResult(data.webAppCheckResult);
      if (emailValidity) {
        setEmailValidity(emailValidity);
        mp.validityEmailChecked(localStep, emailValidity);
      }
      if (features.IS_WIRE_EDIT_PHASE_2_ACTIVATED && contactCertified && customerExistsInOkta) {
        FlowService.removePageFromFlow(state, PageID.CUSTOMER_NAME);
      }

      // duplication alert cases
      if (webAppCheckResult === CustomerCheckResponseEnum.DUPLICATION_ALERT) {
        // Go to last page if customer already exists for events process
        if (event.isCustomJourney) {
          actions.updateAlreadyRegistered(true);
          actions.updateDateSendingOtp('');
          const nextPage = FlowService.nextPage(state, localStep, PageID.REGISTERED);
          navigate(`/${token}/${nextPage}`);
        }
        if (phoneNum || email) {
          formMethods.setError('duplicationAlert', { type: '', message: Utils.isLuceProspectSource(prospectSource) ? t('web.duplicateClient') : t('dcc.duplicate') });
        }
        mp.existingClient();
      } else if (webAppCheckResult === CustomerCheckResponseEnum.CREATION_AUTHORIZED && Utils.exist(emailValidity) && EmailValidityUtils.isEmailValidityStatusNotAccepted(emailValidity!)) {
        formMethods.setError('invalidEmailAlert', { type: '', message: t(EmailValidityUtils.getEmailValidityErrorKey(emailValidity!, Utils.isLuceProspectSource(prospectSource))) });
        setShowEmailError(true);
      } else if (webAppCheckResult === CustomerCheckResponseEnum.CREATION_AUTHORIZED && Utils.exist(emailValidity) && EmailValidityUtils.isEmailValidityStatusToBeConfirmed(emailValidity!)) {
        setWarning(t(EmailValidityUtils.getEmailValidityErrorKey(emailValidity!, Utils.isLuceProspectSource(prospectSource))));
      } else {
        await handleSubmit(informationFormProps, features, pageFlow, webAppCheckResult, emailValidity);
      }
    } catch (err) {
      catchHandleSubmitError(err);
    }
  });

  const catchHandleSubmitError = (err: any) => {
    actions.updateErrorTypeOnError(err.response.data);
    navigate('/error');
  };

  const onSubmitProceed = formMethods.handleSubmit(async (informationFormProps: InformationFormProps) => {
    try {
      await handleSubmit(informationFormProps, featuresActivated, state.flow, lastWebAppCheckResult, lastEmailValidity);
    } catch (err) {
      catchHandleSubmitError(err);
    }
  });

  const handleSubmit = async ({ phonePrefixLabel, phoneNum = '', email = '', preferredMethodOfContact, street, zipCode }: InformationFormProps, features: { [key in FeatureActivated]: boolean }, pageFlow: PageFlow, webAppCheckResult?: CustomerCheckResponseEnum, emailValidity?: EmailValidityStatusEnum) => {
    let { phonePrefix, prefixCountry } = ContactService.getPhonePrefix(phonePrefixLabel);
    // Update mix-panel super properties

    await mp.updateCustomerFlowGlobally(actualToken);
    if (features.IS_WIRE_EDIT_FLOW_ACTIVATED && features.IS_KAKAO_FLOW_ACTIVATED) {
      if (!email && customer.customerContact.email) {
        email = customer.customerContact.email;
      }
      if (!phoneNum && customer.customerContact.phone) {
        phoneNum = customer.customerContact.phone.number;
      }
      if (!phonePrefixLabel && customer.customerContact.phone.prefix && customer.customerContact.phone.prefixCountry) {
        phonePrefix = customer.customerContact.phone.prefix;
        prefixCountry = customer.customerContact.phone.prefixCountry;
      }
    }

    const contactInfos = { prefixCountry, phonePrefix, phoneNum, email, preferredMethodOfContact, street, zipCode, emailValidity };
    actions.updateCustomerInformation(contactInfos);
    actions.updateDateSendingOtp('');
    mp.saveContacts(contactInfos, preferredMethodOfContact);
    if (pageFlow.flow === FlowName.CREATE_GUCCI) {
      const nextPage = FlowService.nextPage(state, localStep, PageID.INFORMATION);
      navigate(`/${token}/${nextPage}`);
    } else if ((features.IS_WIRE_EDIT_FLOW_ACTIVATED && webAppCheckResult === CustomerCheckResponseEnum.UPDATE_AUTHORIZED) || (!features.IS_WIRE_EDIT_FLOW_ACTIVATED && webAppCheckResult === CustomerCheckResponseEnum.CREATION_AUTHORIZED)) {
      const nextPage = FlowService.nextPage(state, localStep);
      navigate(`/${token}/${nextPage}`);
    } else if (features.IS_WIRE_EDIT_FLOW_ACTIVATED && webAppCheckResult === CustomerCheckResponseEnum.CREATION_AUTHORIZED) {
      // if customer doesn't exist and IS_WIRE_EDIT_FLOW_ACTIVATED is activated
      const nextPage = FlowService.nextPage(state, localStep, PageID.INFORMATION);
      flow.authorizedPages = flow.authorizedPages.filter((page) => page !== PageID.VERIFICATION);
      navigate(`/${token}/${nextPage}`);
    } else {
      // This is not supposed to happen, but otherwise we send the customer back to the error page. This is a security
      await api.logInformation(token, LogLevelEnum.ERROR, LogMessageEnum.UNEXPECTED_CHECK_RESPONSE);
      navigate('/error');
    }
  };

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    if (isLoading) {
      return (<FullPageLoader />);
    }
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, prospectSource }}>
        <PageContainer>
          <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
          <ContactTitle prospectSource={prospectSource} />
          {(featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED || featuresActivated.IS_GUCCI_CREATE_USED) && <SubTitle>{t('web.contactInvitation')}</SubTitle>}
          <FormProvider {...formMethods}>
            <FormWarningContext.Provider value={formWarningValue}>
              <form>
                <FormContainer onKeyDown={(keyEvent) => Utils.validateForm(keyEvent, isValid, onSubmit)}>
                  <RadiosContainer>
                    {radioInputsList.map((radio: RadioInput, it: number) => (
                      <RadioLabel htmlFor={`preferredMethodOfContact${it}`} key={radio.value}>
                        <StyledRadioInput
                          type="radio"
                          id={`preferredMethodOfContact${it}`}
                          name="preferredMethodOfContact"
                          value={radio.value}
                          ref={formMethods.register}
                          defaultChecked={radio.prechecked}
                          onChange={() => ContactService.changePreferredMethodOfContact(formMethods, setPhonePrefixLabelLocal, phonePrefixLabelLocal, setPreferredMethodOfContactLocal, setEmailError, setPhoneError, cancelProceedAttempt)}
                        />
                        <CustomRadio />
                        <RadioStringLabel>
                          {t(radio.translationKey)}
                        </RadioStringLabel>
                      </RadioLabel>
                    ))}
                  </RadiosContainer>
                  <PhoneAndEmail
                    preferredMethodOfContact={preferredMethodOfContactLocal}
                    prospectSource={prospectSource}
                    formMethods={formMethods}
                    translatedCountries={translatedCountries}
                    phonePrefixLabel={phonePrefixLabelLocal}
                    setPhonePrefixLabel={setPhonePrefixLabelLocal}
                    phoneUserInputValue={phoneUserInputValue}
                    setPhoneUserInputValue={setPhoneUserInputValue}
                    emailUserInputValue={emailUserInputValue}
                    setEmailUserInputValue={setEmailUserInputValue}
                    phoneError={phoneError}
                    setPhoneError={setPhoneError}
                    emailError={emailError}
                    setEmailError={setEmailError}
                    showPhoneError={showPhoneError}
                    setShowPhoneError={setShowPhoneError}
                    showEmailError={showEmailError}
                    setShowEmailError={setShowEmailError}
                    localStep={PageID.CONTACT}
                  />
                  {duplicationAlert
                    && (
                      <>
                        <ErrorMessage id="duplicationAlert">{duplicationAlert.message}</ErrorMessage>
                        <QueueManagementRedirectButton clientRegistrationStatus={QueueCreationState.ERROR} />
                      </>
                    )}
                  {featuresActivated.IS_CONTACT_FORM_EXTENDED && !featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && !featuresActivated.IS_GUCCI_CREATE_USED
                    && (
                      <>
                        <Street prospectSource={prospectSource} />
                        <ZipCode prospectSource={prospectSource} />
                      </>
                    )}
                </FormContainer>
                <NextButtonContainer isConfirmButton={Boolean(warning)} isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.CONTACT} onSubmit={warning ? onSubmitProceed : onSubmit} />
              </form>
            </FormWarningContext.Provider>
          </FormProvider>
        </PageContainer>
      </ThemeProvider>
    );
  }
  return (<Navigate to={`/${token}`} replace />);
};

export default Contact;
