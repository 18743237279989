import { ProspectSource } from '@/types/enum/prospectSource';
import { CustomerFlow } from '@/types/enum/customerFlow';
import { EmailValidityStatusEnum } from '@/types/enum/email-validity-status.enum';
import { Language } from '@/types/language';
import {
  DateFieldComponentEnum,
  GenderListFieldComponentEnum,
  TextFieldComponentEnum,
  ValuesListFieldComponentEnum
} from '@/types/enum/FieldComponent.enum';

export interface IPreciselyAuthTokenResponse {
    // ex: eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNTFhNTg5ZjBmYjk1YWU5M2J...
    access_token: string,
}

export interface ICheckCustomerResponse {
    // false if customer already exists in CDB/IODS, true otherwise
    result: boolean,
    // true if customer exists only if search by contact not social
    existsOnlyViaContactSearch: boolean,
    // true if the searched customer is unique in kering database, false otherwise
    isUniqueInKeringDb: boolean,
    oktaResult: {
        // true if customer already exists in Okta, false otherwise
        customerExistsInOkta: boolean,
        // true if customer has verified is method of contact at okta account creation, false otherwise
        contactCertified: boolean
    }
}

export enum CustomerCheckResponseEnum {
    DUPLICATION_ALERT = 'DUPLICATION_ALERT',
    CREATION_AUTHORIZED = 'CREATION_AUTHORIZED',
    UPDATE_AUTHORIZED = 'UPDATE_AUTHORIZED'
}

export interface ICheckCustomerResponseV2 {
    cdbResult: {
        isExistingByContactInCDB: boolean;
        isUniqueByContactInCDB: boolean;
        isExistingByOktaSocialLoginInCDB: boolean;
        isUniqueByOktaSocialLoginInCDB: boolean;
        isSocialVerificationFlagActivatedInCDB: boolean;
    },
    oktaResult: {
        customerExistsInOkta: boolean,
        contactCertified: boolean,
    },
    webAppCheckResult: CustomerCheckResponseEnum,
    emailValidity?: EmailValidityStatusEnum,
    eventId?: string,
}

export interface ICheckCustomerByNameAndContactResponse {
    checkByNameAndContactResult: CustomerCheckResponseEnum,
    oktaLogin: string,
}

export interface IResponseProspectSourceAndReCaptchaFrontKey {
    prospectSource: ProspectSource,
    reCaptchaFrontKey: string
}

export interface IResponseDynamicTokenCreated {
    dynamicToken: string
}

export interface IResponseQRCode {
    image: string,
    expiration: string
}

export interface IResponseLineQrCodes {
    jpQrCode: string,
    jpQrCodeExpiration: string,
    twQrCode: string,
    twQrCodeExpiration: string,
    thQrCode: string,
    thQrCodeExpiration: string,
}

export interface IResponseUpsertCustomer {
    success: true,
    creationType: 'sync' | 'async',
    eventId?: string,
}

export interface IResponseCheckPoP {
    data: {
        alreadyExists: boolean
    }
}

export interface IResponseUpdateSocialCustomer {
    success: boolean,
    socialChannel: string
}

export interface IResponseUploadPoP {
    data : {
        success: boolean,
    }
}

export interface ResponseGetCustomerFlow {
    data: {
        customerFlow: CustomerFlow
    }
}

export interface ResponseGetFields {
    data: {
        fieldsResponse: {
            meta: {
                names: string | string[],
                brand: string,
                customerCountry: string,
                customerState?: string,
                storeCountry: string,
                storeState?: string,
                version: string,
                language: string,
                prospectSource?: string
            }
            data: { fieldComponents: (TextFieldComponent | ValuesListFieldComponent | LanguagesListFieldComponent | GenderListFieldComponent | DateFieldComponent)[] }
        }
    }
}

export interface ResponseGetLegalConf {
    legalConfResponse: {
        meta: {
            type: string,
            brand: string,
            country: string,
            state: string,
            language: string,
            version: string,
            legalVersion: string,
            option: string,
        }
        data: {
            privacyLabels: {[key: string]: string},
            minimalAge?: number,
            availableLanguages: LegalConfigurationLanguages[],
            consentComponents: ConsentComponent[],
        }
    }
}

export interface ConsentComponent {
    id: string,
    type: string,
    displayOrder: number,
    label: string,
    style: string[],
    isMandatory?: boolean,
    isPreChecked?: boolean,
    isMarketingConsentChecked?: boolean,
    isDisabledAndPreCheckedForUpdate?: boolean,
}

export interface LegalConfigurationLanguages {
    name: string,
    code: string,
    displayOrder: number
}

export interface CommonFieldComponent {
    isExisting: boolean,
    canBeRequested: boolean,
    isAuthorized: boolean,
    isMandatory: boolean,
    isEditable: boolean,
}

export interface TextFieldComponent extends CommonFieldComponent {
    name: TextFieldComponentEnum,
    type: FieldsTypeEnum.TEXT,
    regex?: string,
}

export interface ValuesListFieldComponent extends CommonFieldComponent {
    name: ValuesListFieldComponentEnum,
    type: FieldsTypeEnum.LIST_OF_VALUES,
    listOfValues: string[] | ListOfValuesItemInterface[],
}

export interface LanguagesListFieldComponent extends CommonFieldComponent {
    name: ValuesListFieldComponentEnum,
    type: FieldsTypeEnum.LIST_OF_VALUES,
    listOfValues: Language[],
}

export interface GenderListFieldComponent extends CommonFieldComponent {
    name: GenderListFieldComponentEnum,
    type: FieldsTypeEnum.LIST_OF_GENDERS,
    listOfValues: ListOfValuesItemInterface[],
}

export interface DateFieldComponent extends CommonFieldComponent {
    name: DateFieldComponentEnum,
    type: FieldsTypeEnum.DATE,
    dateInformation: DateInformationFieldsEndpointResponse,
}

export interface ListOfValuesItemInterface {
    label: string,
    value: string,
}

export interface DateInformationFieldsEndpointResponse {
    minimalAge: number,
    isDayFieldMandatory: boolean,
    isMonthFieldMandatory: boolean,
    isYearFieldMandatory: boolean,
}

export enum FieldsTypeEnum {
    TEXT = 'TEXT',
    LIST_OF_VALUES = 'LIST_OF_VALUES',
    DATE = 'DATE',
    LIST_OF_GENDERS = 'LIST_OF_GENDERS'
}
