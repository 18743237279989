import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import Utils from '@utils/utils';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

type Props = {
  furiganaFirstName: TextFieldComponent,
}

export const FuriganaFirstName: React.FC<Props> = ({ furiganaFirstName }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="furiganaFirstName">{t('dcc.furigana_firstname')}</Label>
        <OptionalLegend>{t('dcc.optional')}</OptionalLegend>
        <Field
          name="furiganaFirstName"
          id="furiganaFirstName"
          ref={register({
            required: furiganaFirstName.isMandatory,
            pattern: Utils.stringToRegex(furiganaFirstName.regex)
          })}
          defaultValue={customer.customerInfo.furigana_firstname}
          disabled={!furiganaFirstName.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
