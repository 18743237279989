import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ThemeProvider } from 'styled-components/macro';
import utils from '@utils/utils';
import {
  CheckBoxInputContainer,
  GdprContainer,
  GdprFooter,
  MainTextContainer,
  SecondaryTextContainer,
} from '@pages/Legal/legal.styles';
import { PrivacyPolicyAndChangeLanguage } from '@pages/Legal/components/PrivacyPolicyAndChangeLanguage';
import api from '@/api';
import {
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateLegalConfigurationConsentComponent,
  updateLightLabels,
  updatePrivacyConf,
} from '@/littleStateMachine/actions';
import { ProgressBar } from '@/common/components';
import { NextButtonContainer, PageContainer } from '@/common/components/containers';
import { CheckboxField, ErrorMessage } from '@/common/components/form';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import mp from '@/services/mixpanel/mixpanel.service';
import { ImgFormLogo, LogoFormWrapper } from '@/common/components/Logo';
import { FullPageLoader } from '@/common/components/Loader';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import ConfirmModal from '@/common/components/modals/ConfirmModal';
import { ThemeType } from '@/types/enum/themeType';
import { Checkbox } from '@/types';
import { Privacy } from '@/types/privacy';
import { CustomerFlow } from '@/types/enum/customerFlow';

const Legal: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.LEGAL);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateErrorTypeOnError, updatePrivacyConf, updateLightLabels, updateCustomerInformation, updateLegalConfigurationConsentComponent });
  const { brand, privacy, legalInformation, prospectSource, flow, translations, customer, mixPanelToken, actualToken, featuresActivated } = state;
  const legalConf = featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API ? legalInformation : privacy;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const { customerContact: { address } } = customer;
  useThemeColor(brand, ThemeType.FORM);

  // current page
  const localStep = PageID.LEGAL;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const { register, handleSubmit, formState, errors } = useForm({ mode: 'onChange' });
  const { isSubmitting, isValid } = formState;

  // local states
  const [isLoading, setIsLoading] = useState(false);
  const [customerWebFlow, setCustomerWebFlow] = useState(CustomerFlow.WIRE_EDIT_CREATION);
  const [modal, setModal] = useState({ open: true });

  const handleCloseModal = async () => {
    setModal({ open: false });
    mp.passedPhone();
  };

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        try {
          setIsLoading(true);
          const { data: { customerFlow } } = await api.getCustomerFlow(token);
          // todo : remove when migration to Wire API is done
          let checkboxLength: number = 0;
          if (featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API) {
            const { data: legalConfPayload } = await api.getLegalConf(translations.privacyLanguageCode, address.country, address.state ?? '', token);
            checkboxLength = utils.checkboxesFromWireAPICount(legalConfPayload?.legalConfResponse?.data?.consentComponents);
            actions.updateLegalConfigurationConsentComponent(legalConfPayload);
          } else {
            const { data: privacyPayload } = await api.fetchPrivacyConf(token, address.country, address.state, translations.privacyLanguageCode);
            checkboxLength = privacyPayload.privacy.checkboxes.length;
            actions.updatePrivacyConf(privacyPayload);
          }
          setCustomerWebFlow(customerFlow);
          // In wire edit phase 2 and in update if privacy contains only one checkbox we skip the display of the legal page.
          const isPageLegalSkipped = featuresActivated.IS_WIRE_EDIT_PHASE_2_ACTIVATED && checkboxLength === 1 && customerFlow === CustomerFlow.WIRE_EDIT_UPDATE;
          setIsLoading(false);
          if (isPageLegalSkipped) {
            const nextPage = FlowService.nextPage(state, localStep);
            navigate(`/${token}/${nextPage}`);
          }
        } catch (err) {
          if (err?.response?.data) {
            actions.updateErrorTypeOnError(err.response.data);
          }
          navigate('/error');
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token]);

  const onSubmit = handleSubmit(async (data) => {
    const checkboxToIgnore = legalConf.checkboxes.filter((checkbox) => checkbox.isLockedAndChecked);
    const privaciesToKeep = Object.values(data).filter((privacyToTest: Privacy) => !checkboxToIgnore.find((checkbox) => checkbox.key === privacyToTest.fieldCode));
    actions.updateCustomerInformation({ privacies: privaciesToKeep });
    const PrivacyforMarketingActivities = utils.isCustomerMarketingConsentChecked(privaciesToKeep, legalConf.checkboxes);
    mp.acceptPrivacy(PrivacyforMarketingActivities);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.privacyLanguageCode, country: customer.customerContact.address.country, prospectSource, isLegal: true }}>
        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              {modal.open && utils.isPassPhoneModalDisplayed(prospectSource) && (
                <ConfirmModal onClose={handleCloseModal}>
                  <p>{t('web.passPhone')}</p>
                </ConfirmModal>
              )}
              <ProgressBar numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} />
              <PrivacyPolicyAndChangeLanguage brandCode={brand.code} />
              <LogoFormWrapper><ImgFormLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></LogoFormWrapper>
              <form>
                {customerWebFlow === CustomerFlow.WIRE_EDIT_UPDATE && featuresActivated.IS_WIRE_EDIT_PHASE_2_ACTIVATED ? (
                  <>
                    <GdprContainer>
                      {legalConf.checkboxes.map((checkbox: Checkbox, i: number) => (
                        <CheckBoxInputContainer key={featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API ? checkbox.key : checkbox.textKey}>
                          <CheckboxField
                            name={`privacy${i}.state`}
                            text={t(featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API ? checkbox.key : checkbox.textKey, true)}
                            isTextHTML
                            required={checkbox.mandatory}
                            parentRef={register({ required: checkbox.mandatory })}
                            defaultChecked={checkbox.preChecked}
                            isLockedAndChecked={checkbox.isLockedAndChecked}
                          />
                          <input type="hidden" name={`privacy${i}.fieldCode`} ref={register} value={checkbox.key} />
                        </CheckBoxInputContainer>
                      ))}
                      {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage> }
                    </GdprContainer>
                    <MainTextContainer>
                      <div dangerouslySetInnerHTML={{ __html: t(legalConf.mainTextKey, true) }} />
                    </MainTextContainer>
                  </>
                ) : (
                  <>
                    <MainTextContainer>
                      <div dangerouslySetInnerHTML={{ __html: t(legalConf.mainTextKey, true) }} />
                    </MainTextContainer>
                    <GdprContainer>
                      {legalConf.checkboxes.map((checkbox: Checkbox, i: number) => (
                        <CheckBoxInputContainer key={featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API ? checkbox.key : checkbox.textKey}>
                          <CheckboxField
                            name={`privacy${i}.state`}
                            text={t(featuresActivated.IS_LEGAL_CONFIGURATION_GET_FROM_WIRE_API ? checkbox.key : checkbox.textKey, true)}
                            isTextHTML
                            required={checkbox.mandatory}
                            parentRef={register({ required: checkbox.mandatory })}
                            defaultChecked={checkbox.preChecked}
                            isLockedAndChecked={checkbox.isLockedAndChecked}
                          />
                          <input type="hidden" name={`privacy${i}.fieldCode`} ref={register} value={checkbox.key} />
                        </CheckBoxInputContainer>
                      ))}
                      {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage> }
                    </GdprContainer>
                  </>
                )}
                {t(legalConf.secondaryTextKey, true) && t(legalConf.secondaryTextKey, true).trim()
                  && (
                    <SecondaryTextContainer>
                      <div dangerouslySetInnerHTML={{ __html: t(legalConf.secondaryTextKey, true) }} />
                    </SecondaryTextContainer>
                  )}
                <GdprFooter>
                  <div dangerouslySetInnerHTML={{ __html: t(legalConf.footerTextKey, true) }} />
                </GdprFooter>
                <NextButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.LEGAL} legalInformation={legalConf} onSubmit={onSubmit} isCustomTranslationUsed={featuresActivated.IS_WIRE_EDIT_PHASE_2_ACTIVATED && customerWebFlow === CustomerFlow.WIRE_EDIT_UPDATE} />
              </form>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default Legal;
