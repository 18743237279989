import React from 'react';
import { useTranslation } from '@services/hooks';
import FlowService from '@services/flow.service';
import { useStateMachine } from 'little-state-machine';
import { useNavigate } from 'react-router-dom';
import SocialLoginButton from '@pages/CountryPage/components/SocialLoginButtons/SocialLoginButton';
import ProfileIcon from '@assets/img/icons/ProfileIcon';
import { PageID } from '@/types/enum/pageID';

interface Props {
  localStep: PageID,
}

const ContinueWithWireButton: React.FC<Props> = ({ localStep }) => {
  const { state } = useStateMachine();
  const { actualToken } = state;
  const navigate = useNavigate();
  const t = useTranslation();

  const onContinueWithWireClicked = () => {
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${actualToken}/${nextPage}`);
  };

  return (
    <SocialLoginButton id="continueWithWireBtn" label={t('web.continueManualy')} logo={<ProfileIcon />} onClick={onContinueWithWireClicked} />
  );
};

export default ContinueWithWireButton;
