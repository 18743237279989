import Utils from '@utils/utils';
import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field, OptionalLegend } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { TextFieldComponent } from '@/types/api';

interface Props {
  company: TextFieldComponent;
}

export const CompanyName: React.FC<Props> = ({ company }) => (
  <ConnectForm>
    {({
      register,
      prospectSource,
      t
    }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="companyName">{t('web.companyName')}</Label>
        {!company?.isMandatory && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
        <Field
          id="companyName"
          name="companyName"
          ref={register({
            required: company.isMandatory,
            pattern: Utils.stringToRegex(company?.regex),
            validate: (value) => Utils.doesNotContainOnlySpaces(value)
          })}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'organization'}
          disabled={!company.isEditable}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
